import React, { createContext, useState, ReactNode } from "react";
import { AccountType } from "./bday-gender";

type OnboardingContextType = {
  accountType: AccountType | null;
  setAccountType: (type: AccountType) => void;
};

export const OnboardingContext = createContext<OnboardingContextType>({
  accountType: null,
  setAccountType: () => {},
});

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [accountType, setAccountType] = useState<AccountType | null>(null);

  return (
    <OnboardingContext.Provider value={{ accountType, setAccountType }}>
      {children}
    </OnboardingContext.Provider>
  );
};

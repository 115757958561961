import { faChevronDoubleUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "../components/atoms/Text";
import clsx from "clsx";
import React, { useState, useEffect } from "react";

interface ScrollToTopButtonProps {
  containerRef?: React.RefObject<HTMLElement | null>; // Optional ref to a container
  offset?: number; // Optional scroll offset to trigger the button appearance
}

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({ containerRef, offset = 50 }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to determine visibility based on scroll position
  const handleScroll = () => {
    const target = containerRef?.current || window;
    const scrollPosition = target instanceof HTMLElement ? target.scrollTop : window.scrollY;
    setIsVisible(scrollPosition > offset);
  };

  // Handle scroll-to-top action
  const handleClick = () => {
    const target = containerRef?.current || window;
    target.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const target = containerRef?.current || window;
    target.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check to set the visibility of the button

    return () => {
      target.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, containerRef]);

  return (
    <button
      onClick={handleClick}
      className={clsx(
        "fixed bottom-48 right-0 p-3 rounded-l-md flex flex-col gap-2 z-50 bg-[#FE635F] text-white shadow-lg transition-opacity duration-300",
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      )}
      aria-label="Scroll to top"
    >
      <FontAwesomeIcon icon={faChevronDoubleUp} />
    </button>
  );
};

export default ScrollToTopButton;

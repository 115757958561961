import { ObBirthdayPicker } from "../../widgets/pickers/birthday-picker";
import { ObGenderPicker } from "shared-web-react/dist/widgets/gender-picker";
import { ObOrientationPicker } from "../../widgets/bio/orientation-picker";
import {
  Relationship_Statuses_Enum,
  Relationship_Types_Enum,
  useDeleteRelationShipPartner554Mutation,
  useGetUserTypeQuery,
  useInsertRelationshipPartner554Mutation,
  useUpdatePartnerGender559Mutation,
  useUpdateRelationshipPartner554Mutation,
  useUpdateUserTypeMutation,
  useUserGhostRelationShips554Query,
} from "shared/dist/__generated__/components";
import { ObScreen } from "./widgets";
import React, { useEffect } from "react";
import { classNames } from "shared/dist/util";
import { useMyId } from "shared/dist/auth-data";
import { Text } from "../../components/atoms/Text";
import { Button } from "../../components/atoms/Button";
import { ObScreenProps } from "../../widgets/bio/bio-types";
import { ScreenNameEditor } from "../../widgets/pickers/screen-name-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUser, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useDebounce } from "use-debounce";
import { set } from "ts-pattern/dist/patterns";
import mixpanel from "mixpanel-browser";
import { OnboardingContext } from "./onboarding-context";

// Define AccountType
export type AccountType = "Individual" | "Joint";

// Define ACCOUNT_TYPES
const ACCOUNT_TYPES: Array<{
  value: AccountType;
  label: string;
  description: string;
}> = [
  {
    value: "Individual",
    label: "Individual",
    description:
      "Create a profile just for yourself. Perfect for singles or those exploring on their own.",
  },
  {
    value: "Joint",
    label: "Joint Account",
    description: "Create a shared profile with your partner. Ideal for couples exploring together.",
  },
];

export function ObBirthday({
  onNext,
  setLoading,
  setDisableButton,
}: {
  onNext?: () => void;
  setLoading?: (state: boolean) => void;
  setDisableButton: (b: boolean) => void;
}): React.JSX.Element {
  const [_, setFakeLoading] = React.useState(true);

  // Disable the Next button initially
  React.useEffect(() => {
    console.log("ObBirthday: Setting initial disabled state");
    setDisableButton(true);
  }, []);

  React.useEffect(() => {
    const tm = setTimeout(() => {
      setFakeLoading(false);
    }, 2500);
    return () => tm && clearTimeout(tm);
  });

  React.useEffect(() => {
    mixpanel.track("Onboarding Flow: Birthday entry");
  }, []);

  return (
    <ObScreen title="When were you born?">
      <div className={classNames("flex flex-col h-full w-full gap-2 pb-4 items-stretch")}>
        <ObBirthdayPicker setDisableButton={setDisableButton} />
      </div>
    </ObScreen>
  );
}

export function ObGender({
  setDisableButton,
  onNext,
  setLoading,
}: {
  onNext?: () => void;
  setLoading?: (state: boolean) => void;
  setDisableButton: (state: boolean) => void;
}): React.JSX.Element {
  const myId = useMyId();

  React.useEffect(() => {
    mixpanel.track("Onboarding Flow: Gender selection");
  }, []);

  return (
    <ObScreen title="What's your gender?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          {" "}
          <ObGenderPicker
            forUserId={myId}
            defaultGenderId="gender_man"
            // buttonClassName="!pl-2"
            // suppressToast
            setDisabled={setDisableButton}
          />
        </div>
      </div>
    </ObScreen>
  );
}

export function ObOrientation({
  setDisableButton,
}: {
  setDisableButton: (b: boolean) => void;
}): React.JSX.Element {
  React.useEffect(() => {
    mixpanel.track("Onboarding Flow: Orientation selection");
  }, []);

  return (
    <ObScreen title="What's your sexual orientation?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          <ObOrientationPicker setDisableButton={setDisableButton} />
        </div>
      </div>
    </ObScreen>
  );
}

export function ObAccountType({
  setDisableButton,
  onNext,
}: {
  setDisableButton: (state: boolean) => void;
  onNext?: () => void;
}): React.JSX.Element {
  const [selectedType, setSelectedType] = React.useState<AccountType | null>(null);
  const { setAccountType } = React.useContext(OnboardingContext);

  React.useEffect(() => {
    mixpanel.track("Onboarding Flow: Account type selection");
  }, []);

  // Disable the button if no account type is selected
  React.useEffect(() => {
    setDisableButton(!selectedType);
  }, [selectedType, setDisableButton]);

  const handleAccountTypeSelect = (type: AccountType) => {
    setSelectedType(type);
    setAccountType(type);
    console.log("Account type selected:", type); // Debug log

    // Automatically proceed to next screen after selection
    if (onNext) {
      setTimeout(() => {
        onNext();
      }, 300); // Small delay for visual feedback
    }
  };

  return (
    <ObScreen title="What kind of account would you like?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          <Text as="p" size="xs" weight="regular" className="text-white mb-6">
            Choose the account type that best fits your needs.
          </Text>
          <div className="flex flex-col gap-4">
            {ACCOUNT_TYPES.map((type) => (
              <Button
                key={type.value}
                variant={selectedType === type.value ? "primary" : "outline"}
                className="w-full"
                onClick={() => handleAccountTypeSelect(type.value)}
              >
                {type.label}
              </Button>
            ))}
          </div>

          <Text as="p" size="xs" weight="regular" className="text-white mt-6 text-center">
            {selectedType
              ? ACCOUNT_TYPES.find((type) => type.value === selectedType)?.description
              : "Select an account type to see more details"}
          </Text>
        </div>
      </div>
    </ObScreen>
  );
}

export function ObPartnerInfo({
  setLoading,
  setDisableButton,
  setRelationshipId,
}: {
  setLoading?: (state: boolean) => void;
  setDisableButton: (b: boolean) => void;
  setRelationshipId: (id: string) => void;
}): React.JSX.Element {
  const [name, setNameInner] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);
  const { data, loading } = useUserGhostRelationShips554Query({
    variables: {
      user_id: useMyId()!,
    },
    fetchPolicy: "network-only",
  });
  const [debouncedName] = useDebounce(name, 500);
  const my_id = useMyId()!;

  // Just use one mutation
  const [updatePartnerName, { loading: mutationLoading }] =
    useUpdateRelationshipPartner554Mutation();
  const [insertPartnerName] = useInsertRelationshipPartner554Mutation();
  React.useEffect(() => {
    if (data && data.relationships && data.relationships.length > 0) {
      setRelationshipId(data.relationships[0]?.id || "");
      setNameInner(data.relationships[0]?.partner_name || "");
    }
  }, [data]);

  const validateAndSetUrl = React.useCallback(
    async (newScreenName: string) => {
      // Don't run validation if name is empty or too short
      if (!newScreenName || newScreenName.length < 3) {
        setError("Name must be at least 3 characters long");
        setDisableButton?.(true);
        return;
      }

      setError(null);
      setDisableButton?.(true);

      if (!my_id) {
        setError("User ID is not available");
        return;
      }

      try {
        const existingRelationship = data?.relationships[0]?.id;

        if (existingRelationship) {
          await updatePartnerName({
            variables: {
              id: existingRelationship,
              partner_name: newScreenName,
            },
            refetchQueries: ["UserGhostRelationShips554"],
          });
        } else {
          await insertPartnerName({
            variables: {
              relationship: {
                from_user_id: my_id,
                partner_name: newScreenName,
                status: Relationship_Statuses_Enum.Accepted,
                type: Relationship_Types_Enum.Ghost,
              },
            },
            refetchQueries: ["UserGhostRelationShips554"],
          });
        }
      } catch (error) {
        console.error("Operation failed:", error);
        setError("Failed to update partner name. Please try again.");
      } finally {
        setDisableButton?.(false);
        setLoading?.(false);
      }
    },
    [updatePartnerName, my_id, data, setDisableButton, setLoading]
  );

  React.useEffect(() => {
    validateAndSetUrl(debouncedName);
  }, [debouncedName, validateAndSetUrl]);

  React.useEffect(() => {
    if (loading || mutationLoading) {
      setDisableButton?.(true);
    } else {
      setDisableButton?.(false);
    }
  }, [loading, mutationLoading, setDisableButton]);

  return (
    <>
      <Text as="p" size="xs" weight="regular" className="text-white my-4">
        {name ? `Your partner's name is ${name}` : "What's your partner's name?"}
      </Text>
      {error && (
        <Text as="p" size="xs" weight="regular" className="text-red-500 mb-2">
          {error}
        </Text>
      )}
      <input
        type="text"
        value={name}
        onChange={(e) => setNameInner(e.target.value)}
        className="border-b border-gray-400 bg-transparent pl-1 rounded-none w-full text-white focus:outline-none"
        placeholder="DreamyDancer"
      />
    </>
  );
}

export function ObPartnerName({
  setDisableButton,
  onNext,
}: {
  setDisableButton: (state: boolean) => void;
  onNext?: () => void;
}): React.JSX.Element {
  const [partnerName, setPartnerName] = React.useState<string>("");
  const isValid = partnerName.length >= 3;
  const my_id = useMyId()!;
  const [debouncedPartnerName] = useDebounce(partnerName, 500);
  const [error, setError] = React.useState<string | null>(null);

  // Get existing relationship data
  const { data, loading } = useUserGhostRelationShips554Query({
    variables: {
      user_id: my_id,
    },
    fetchPolicy: "network-only",
  });

  // Set up mutations
  const [updatePartnerName, { loading: updateLoading }] = useUpdateRelationshipPartner554Mutation();
  const [insertPartnerName, { loading: insertLoading }] = useInsertRelationshipPartner554Mutation();

  React.useEffect(() => {
    mixpanel.track("Onboarding Flow: Partner name entry");
  }, []);

  // Update button state based on validation and loading state
  React.useEffect(() => {
    setDisableButton(!isValid || loading || updateLoading || insertLoading);
  }, [isValid, loading, updateLoading, insertLoading, setDisableButton]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPartnerName(e.target.value);
  };

  // Save partner name when valid
  React.useEffect(() => {
    const savePartnerName = async () => {
      if (!debouncedPartnerName || debouncedPartnerName.length < 3 || !my_id) {
        return;
      }

      try {
        const existingRelationship = data?.relationships?.[0]?.id;

        if (existingRelationship) {
          await updatePartnerName({
            variables: {
              id: existingRelationship,
              partner_name: debouncedPartnerName,
            },
            refetchQueries: ["UserGhostRelationShips554"],
          });
        } else {
          await insertPartnerName({
            variables: {
              relationship: {
                from_user_id: my_id,
                partner_name: debouncedPartnerName,
                status: Relationship_Statuses_Enum.Accepted,
                type: Relationship_Types_Enum.Ghost,
              },
            },
            refetchQueries: ["UserGhostRelationShips554"],
          });
        }
        setError(null);
      } catch (err) {
        console.error("Failed to save partner name:", err);
        setError("Failed to save partner name. Please try again.");
      }
    };

    savePartnerName();
  }, [debouncedPartnerName, my_id, data, updatePartnerName, insertPartnerName]);

  return (
    <ObScreen title="What's your partner's name?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow">
          <Text as="p" size="xs" weight="regular" className="text-white mb-6">
            For joint accounts, we need your partner's name to personalize your experience.
          </Text>
          <div className="mb-6">
            <label className="block text-white text-sm font-medium mb-2">Partner's Name</label>
            <input
              type="text"
              value={partnerName}
              onChange={handleChange}
              onKeyPress={(e) => {
                if (e.key === "Enter" && isValid) {
                  onNext && onNext();
                }
              }}
              className="border-b border-gray-400 bg-transparent pl-1 rounded-none w-full text-white focus:outline-none"
              placeholder="Enter your partner's name"
            />
            {partnerName.length > 0 && !isValid && (
              <p className="text-red-500 text-xs mt-1">Name must be at least 3 characters</p>
            )}
            {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
          </div>
        </div>
      </div>
    </ObScreen>
  );
}

export function ObCoupleGender({
  setDisableButton,
  onNext,
}: {
  setDisableButton: (state: boolean) => void;
  onNext?: () => void;
}): React.JSX.Element {
  const [selectedGender, setSelectedGender] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  // Will need to be replaced with the actual mutation when available
  const saveGender = async (gender: string) => {
    try {
      console.log("Saving couple gender:", gender);
      // This is where you'll add the mutation call when it's available
      return true;
    } catch (err) {
      console.error("Failed to save couple gender:", err);
      setError("Failed to save gender. Please try again.");
      return false;
    }
  };

  React.useEffect(() => {
    mixpanel.track("Onboarding Flow: Couple gender selection");
  }, []);

  // Update button state based on selection
  React.useEffect(() => {
    setDisableButton(!selectedGender);
  }, [selectedGender, setDisableButton]);

  const handleGenderSelect = async (gender: string) => {
    setSelectedGender(gender);
    const success = await saveGender(gender);

    if (success && onNext) {
      // Small delay for visual feedback
      setTimeout(() => {
        onNext();
      }, 300);
    }
  };

  const coupleGenderOptions = [
    { value: "MM", label: "Man & Man" },
    { value: "MF", label: "Man & Woman" },
    { value: "FF", label: "Woman & Woman" },
    { value: "MX", label: "Man & Non-binary" },
    { value: "FX", label: "Woman & Non-binary" },
    { value: "XX", label: "Non-binary & Non-binary" },
    { value: "Other", label: "Other" },
  ];

  return (
    <ObScreen title="What's your couple's gender?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          <Text as="p" size="xs" weight="regular" className="text-white mb-6">
            This helps us personalize your experience and connect you with the right community.
          </Text>

          <div className="flex flex-col gap-3">
            {coupleGenderOptions.map((option) => (
              <Button
                key={option.value}
                variant={selectedGender === option.value ? "primary" : "outline"}
                className="w-full"
                onClick={() => handleGenderSelect(option.value)}
              >
                {option.label}
              </Button>
            ))}
          </div>

          {error && <p className="text-red-500 text-xs mt-4">{error}</p>}
        </div>
      </div>
    </ObScreen>
  );
}

export function ObPartnerGender({
  setDisableButton,
  onNext,
}: {
  setDisableButton: (state: boolean) => void;
  onNext?: () => void;
}): React.JSX.Element {
  const [selectedGender, setSelectedGender] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const my_id = useMyId()!;

  // Get existing relationship data
  const { data, loading: relationshipLoading } = useUserGhostRelationShips554Query({
    variables: {
      user_id: my_id,
    },
    fetchPolicy: "network-only",
  });

  // Set up mutation
  const [updatePartnerGender, { loading: updateLoading }] = useUpdatePartnerGender559Mutation();

  React.useEffect(() => {
    mixpanel.track("Onboarding Flow: Partner gender selection");
  }, []);

  // Update button state based on validation and loading state
  React.useEffect(() => {
    setDisableButton(relationshipLoading || updateLoading || !selectedGender);
  }, [relationshipLoading, updateLoading, selectedGender, setDisableButton]);

  const savePartnerGender = async (genderId: string) => {
    if (!data?.relationships || data.relationships.length === 0) {
      setError("No relationship found. Please go back and set up your partner's name first.");
      return false;
    }

    const relationshipId = data.relationships[0]?.id;
    if (!relationshipId) {
      setError("No relationship ID found. Please try again.");
      return false;
    }

    try {
      setLoading(true);
      const result = await updatePartnerGender({
        variables: {
          id: relationshipId,
          gender_id: genderId,
        },
      });

      if (result.data?.update_relationships?.returning?.[0]?.id) {
        return true;
      } else {
        setError("Failed to update partner's gender. Please try again.");
        return false;
      }
    } catch (error) {
      console.error("Error updating partner gender:", error);
      setError("An error occurred. Please try again.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleGenderSelect = async (genderId: string) => {
    setSelectedGender(genderId);
    const success = await savePartnerGender(genderId);

    if (success && onNext) {
      // Small delay for visual feedback
      setTimeout(() => {
        onNext();
      }, 300);
    }
  };

  const genderOptions = [
    { id: "gender_man", label: "Man" },
    { id: "gender_woman", label: "Woman" },
    { id: "gender_non_binary", label: "Non-binary" },
    { id: "gender_other", label: "Other" },
  ];

  return (
    <ObScreen title="What's your partner's gender?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          <Text as="p" size="xs" weight="regular" className="text-white mb-6">
            This helps us personalize your joint experience and connect you with the right
            community.
          </Text>

          <div className="flex flex-col gap-3">
            {genderOptions.map((option) => (
              <Button
                key={option.id}
                variant={selectedGender === option.id ? "primary" : "outline"}
                className="w-full"
                onClick={() => handleGenderSelect(option.id)}
                disabled={loading}
              >
                {option.label}
              </Button>
            ))}
          </div>

          {error && <p className="text-red-500 text-xs mt-4">{error}</p>}
        </div>
      </div>
    </ObScreen>
  );
}

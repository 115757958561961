import {
  All_Preferences_300Fragment,
  UpdateDiscoveryPreferences300MutationVariables,
  useGetAllDiscoveryPreferences300Query,
  useGetDiscoveryPreferences300Query,
  useInsertDiscoveryPreference300Mutation,
} from "shared/dist/__generated__/components";
import { Maybe, classNames, sortBy, uniqWith } from "shared/dist/util";
import { convert, distToLocale } from "shared-web-react/dist/util/units";

import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { useGenderLookup } from "shared-web-react/dist/helpers/gender";
import { useMyId } from "shared/dist/auth-data";

export type PartnerData = { slug: string; relationshipId: string };
export type DatingPrefs = Omit<All_Preferences_300Fragment, "__typename">;
export type DatingPrefsUpdate = UpdateDiscoveryPreferences300MutationVariables["update"];
export type DatingPrefsUpdateFunction = (prefs: DatingPrefsUpdate) => Promise<any>;
export type DiscoveryCommonProps = {
  disabled: boolean;
  partner?: PartnerData | null;
  prefs: null | DatingPrefs;
  onUpdate: DatingPrefsUpdateFunction;
};

export function DatingPreferencesSummary(): React.JSX.Element {
  const my_id = useMyId();
  const { data: lookup } = useGenderLookup();
  const { data, loading } = useAllDiscoveryPreferences(my_id);
  if (loading) {
    return (
      <span className={classNames("text-sm")}>
        <Spinner />
      </span>
    );
  }
  if (!data || data.just_show_everyone) {
    return <span className={classNames("text-sm")}>Showing you everyone</span>;
  }
  const dist = distToLocale({
    dist: data.max_distance,
    units: "m",
    greaterThanOverride: ({ dist, units }) =>
      convert({ dist, from: units, to: "mi" }) > 2400 ? "Anywhere" : undefined,
  });
  const genderNames = data?.genders?.map((g) => lookup?.[g.gender_id]?.name?.en_US ?? g.gender_id);
  const genders =
    data.genders.length === 0
      ? "no genders :("
      : data.genders.length === 1
        ? `Just ${genderNames[0]}`
        : data.genders.length === 2
          ? `Just ${genderNames[0]} and ${genderNames[1]}`
          : `${genderNames[0]}, ${genderNames[1]} and ${data.genders.length - 2} others`;

  return (
    <span className={classNames("text-sm")}>
      {`${data.min_age}-${data.max_age}, ${dist}, ${genders}`}
    </span>
  );
}

export function useAllDiscoveryPreferences(user_id: Maybe<string>) {
  const { data, ...query } = useGetAllDiscoveryPreferences300Query({
    variables: { id: user_id! },
    skip: !user_id,
    fetchPolicy: "cache-and-network",
  });
  const genderLookup = useGenderLookup();
  const mergedData: undefined | Omit<All_Preferences_300Fragment, "id"> = data
    ?.discovery_preferences?.length
    ? {
        __typename: "discovery_preferences",
        max_age: Math.max(...data.discovery_preferences.map((d) => d.max_age)),
        min_age: Math.min(...data.discovery_preferences.map((d) => d.min_age)),
        max_distance: Math.max(...data.discovery_preferences.map((d) => d.max_distance)),
        just_show_everyone: data.discovery_preferences.some((p) => p.just_show_everyone),
        include_existing_connections: data.discovery_preferences.some(
          (p) => p.include_existing_connections
        ),
        include_unverified_users: data.discovery_preferences.some(
          (p) => p.include_unverified_users
        ),

        genders: sortBy(
          uniqWith(
            data.discovery_preferences.flatMap((d) => d.genders),
            (a, b) => a.gender_id === b.gender_id
          ),
          (g) => genderLookup?.data?.[g.gender_id]?.seq_no ?? 0
        ).reverse(),
      }
    : undefined;
  return {
    data: mergedData,
    ...query,
  };
}

/**
 *
 * @param selector this is either { user_id: string } or { relationship_id: string } and the idea
 * that this will elt us get disc preferences for a user or relationship.  This will also create the
 * record if none exist
 * @returns
 */
export function useGetDiscoveryPreferencesAndInsertIfNull(
  selector: { user_id: string } | { relationship_id: string }
): Pick<
  ReturnType<typeof useGetDiscoveryPreferences300Query>,
  "refetch" | "error" | "loading" | "called"
> & {
  data: null | DatingPrefs;
} {
  const id = "user_id" in selector ? selector.user_id : selector.relationship_id;
  const [insertPreferences, mutationResults] = useInsertDiscoveryPreference300Mutation();
  const { data, loading, refetch, error, called } = useGetDiscoveryPreferences300Query({
    variables: { id },
  });
  React.useEffect(() => {
    if (
      mutationResults.called ||
      mutationResults.error ||
      mutationResults.loading ||
      !called ||
      data?.discovery_preferences?.[0] ||
      loading
    )
      return;

    console.log("🚀 ~ file: dating-settings-utilities.tsx:41 calling mutate");
    insertPreferences({
      variables: {
        object: { ...selector, just_show_everyone: "user_id" in selector },
      },
    });
  }, [
    selector,
    insertPreferences,
    mutationResults.called,
    mutationResults.error,
    mutationResults.loading,
    data,
    loading,
  ]);
  return {
    refetch,
    data:
      data?.discovery_preferences?.[0] ??
      mutationResults?.data?.insert_discovery_preferences_one ??
      null,
    loading: loading || mutationResults?.loading,
    error: error || mutationResults?.error,
    called: called || mutationResults?.called,
  };
}

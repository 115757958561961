import React, { useEffect } from "react";

import { useTypedParams } from "react-router-typesafe-routes/dom";
import {
  Onboarding_Statuses_Enum,
  useCompleteOnboardingMutation,
  useEventDetailsBySlug553Query,
  useEventDetailsBySlug556Query,
  useJoinPublicEvent558Mutation,
  useSetEmailMutation,
  useSetUrlMutation,
  useSetUserObStatusMutation,
  useUpdateScreenNameMutation,
} from "shared/dist/__generated__/components";
import { allRoutes } from "../../../../util/routes";
import { Helmet } from "react-helmet";
import simmrLogo from "../../../../assets/simmr-logo-red.png";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { useEnv } from "shared/dist/util/env";
import { DateTime } from "luxon";
import { formatDate } from "../../../../util/datetime-formatting";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Text } from "../../../../components/atoms/Text";
import clsx from "clsx";
import Linkify from "linkify-react";
import { Container } from "../../../../widgets/container";
import { SingleFeatureWidget } from "../../../../widgets/single-feature";
import discovery from "../../../../assets/discovery-join.png";
import homescreenHow from "../../../../assets/homescreen-how.png";
import homescreenConnect from "../../../../assets/homescreen-connect.png";
import { Button } from "../../../../components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { FacebookPixelComponent, YMHPixel } from "../../../../widgets/tracking-pixels";
import mixpanel from "mixpanel-browser";
import { BigModal } from "../../../../widgets/bio/big-modal";
import { Input } from "../../../../widgets/input";
import { useBeginSmsLogin, useVerifySmsLogin } from "shared/dist/sms-auth-flows";
import { AuthData, useMyId, useSetAuthData } from "shared/dist/auth-data";
import { useMyInfo } from "../../../bio/bio-settings";

const PublicEventsDetailT: React.FC = () => {
  const { event_slug } = useTypedParams(allRoutes.EVENTS.PUBLIC.DETAILS);
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const { data, loading: eventDataLoading } = useEventDetailsBySlug556Query({
    variables: { url_slug: event_slug! },
  });
  const id = useMyId();
  const baseUri = useEnv("REACT_APP_BASE_URI");
  const [showSignupFlow, setShowSignupFlow] = React.useState(false);
  const [nonce, setNonce] = React.useState<null | string>(null);
  const [otp, setOtp] = React.useState("");
  const [signUpInfo, setSignUpInfo] = React.useState({
    name: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = React.useState<{ phoneNumber?: string; otp?: string }>({});
  const [apiError, setApiError] = React.useState<null | string>(null);
  const [loading, setLoading] = React.useState(false);
  const [setUrlMutation, setUrlResults] = useSetUrlMutation();
  const [setScreenNameMutation] = useUpdateScreenNameMutation();
  const [setEmailMutation] = useSetEmailMutation();
  const setAuthData = useSetAuthData();
  const eventId = data?.event_public_summaries[0]?.id;
  const beginSmsLogin = useBeginSmsLogin();
  const verifySmsLogin = useVerifySmsLogin();
  const [completeMutation] = useCompleteOnboardingMutation();
  const [joinMutation, joinMutationResults] = useJoinPublicEvent558Mutation();
  const [setObStatusMutation] = useSetUserObStatusMutation();

  if (typeof event_slug === "undefined") {
    return <ErrorComp caption="Invalid event slug" />;
  }
  const openModal = React.useCallback(() => {
    mixpanel.track(`User opened new quick signup flow for ${event?.name}`, { event_id: eventId });
    setShowSignupFlow(true);
  }, [eventId]);

  useEffect(() => {
    const updateInfo = async () => {
      const generateUniqueSlug = (base: string) => {
        const uniqueSuffix = Math.random().toString(36).substring(2, 5);
        return `${base}_${uniqueSuffix}`;
      };

      const baseUrl = signUpInfo.name
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "_")
        .replace(/-+/g, "_");

      const uniqueUrl = generateUniqueSlug(baseUrl);

      await setUrlMutation({ variables: { slug: uniqueUrl, id: id! } });
      await setScreenNameMutation({ variables: { screen_name: signUpInfo.name, id: id! } });
      await setEmailMutation({ variables: { email: signUpInfo.email, id: id! } });
    };
    if (nonce && id) updateInfo();
  }, [nonce]);

  const setAuth = React.useCallback(
    async (ad: AuthData) => {
      setAuthData(ad);

      // Wait for state to update
      await new Promise((resolve) => setTimeout(resolve, 300));

      try {
        if (!ad.token) {
          throw new Error("❌ No auth token available. Cannot complete onboarding.");
        }

        const generateUniqueSlug = (base: string) => {
          const uniqueSuffix = Math.random().toString(36).substring(2, 5);
          return `${base}_${uniqueSuffix}`;
        };

        const baseUrl = signUpInfo.name
          .toLowerCase()
          .replace(/[^a-z0-9]/g, "_")
          .replace(/-+/g, "_");

        const uniqueUrl = generateUniqueSlug(baseUrl);

        await setObStatusMutation({
          variables: {
            user_id: ad.userId!,
            event_id: eventId!,
            slug: uniqueUrl,
            email: signUpInfo.email,
            screen_name: baseUrl,
          },
        });
        await new Promise((resolve) => setTimeout(resolve, 200));
      } catch (error) {
        console.error("❌ completeMutation failed:", error);
      }

      await joinMutation({
        variables: {
          event_id: eventId!,
          user_id: ad.userId!,
        },
      });

      navigate(allRoutes.EVENT_FROM_SLUG.buildPath({ slug: event_slug! }));
    },
    [setAuthData, navigate, completeMutation, eventId, id, signUpInfo]
  );

  const event = data?.event_public_summaries[0];

  if (!event) return <ErrorComp caption={`Event ${event_slug} not found`} />;

  if (loading) return <SpinnerCentered />;

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission

    const phoneRegex = /^\d{10}$/;
    const isPhoneValid = phoneRegex.test(signUpInfo.phone.replace(/\D/g, ""));
    if (!isPhoneValid) {
      setErrors((prev) => ({ ...prev, phoneNumber: "Please enter a valid 10-digit phone number" }));
      return;
    }

    if (nonce) {
      if (!otp || otp.replace(/\D/g, "").length !== 6) {
        setErrors((prev) => ({ ...prev, otp: "Please enter a valid 6-digit code" }));
        return;
      }
      await verifySmsLogin({
        nonce,
        itu_country_code: "1",
        setApiError,
        setAuth,
        setLoading,
        otp: otp.replace(/\D/g, ""),
        phone_number: signUpInfo.phone,
        isAdmin: false,
      });

      // @ts-ignore
      window.gtag("event", "conversion", {
        send_to: "AW-16648321787/izhKCMHmmaMaEPv1xII-",
      });
    } else {
      await beginSmsLogin({
        setApiError,
        setNonce,
        setLoading,
        phone_number: signUpInfo.phone,
        itu_country_code: "1",
        eventId: eventId!,
      });

      // await refetch();
    }
  };

  const eventImgUrl =
    baseUri.replace(/\/$/, "") +
    allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({
      slug: event.url_slug!,
    });

  return (
    <>
      {event_slug === "Mardi-Gras-New-Orleans-2025" && (
        <FacebookPixelComponent pixel_id="2065151360670040" />
      )}
      {showSignupFlow && (
        <BigModal onDismiss={() => {}} className="w-full">
          <div className="flex flex-col items-center justify-center p-8 bg-[#2A2C38] rounded-3xl shadow-2xl max-w-sm mx-auto">
            <Text as="p" weight="bold" className="text-gray-400 text-center">
              Let's Get You <br />
              Signed Up!
            </Text>

            <Text as="p" size="xs" weight="bold" className="text-gray-400 py-5 text-center">
              {!nonce
                ? "Just a little more info before you can meet like-minded people."
                : "We texted you a code to verify your phone number. Enter it below."}
            </Text>

            {nonce ? (
              <>
                <Text as="p" size="xxs" className="text-gray-400 py-2 self-start">
                  OTP
                </Text>
                <Input type="text" placeholder="123456" onChange={(e) => setOtp(e.target.value)} />
              </>
            ) : (
              <>
                <Text as="p" size="xxs" className="text-gray-400 py-2 self-start">
                  Name
                </Text>
                <Input
                  type="text"
                  placeholder="Justin"
                  onChange={(e) => setSignUpInfo({ ...signUpInfo, name: e.target.value })}
                />
                <Text as="p" size="xxs" className="text-gray-400 py-2 self-start">
                  Phone Number
                </Text>{" "}
                <Input
                  type="text"
                  placeholder="323-555-1234"
                  onChange={(e) => setSignUpInfo({ ...signUpInfo, phone: e.target.value })}
                />
                <Text as="p" size="xxs" className="text-gray-400 py-2 self-start">
                  Email
                </Text>{" "}
                <Input
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setSignUpInfo({ ...signUpInfo, email: e.target.value })}
                />
              </>
            )}
            <Text as="p" size="xxs" className="text-red-500 py-2 self-start">
              {errors.otp}
            </Text>
            <Text as="p" size="xxs" className="text-red-500 py-2 self-start">
              {errors.phoneNumber}
            </Text>
            <Text as="p" size="xxs" className="text-red-500 py-2 self-start">
              {apiError}
            </Text>
            <Text as="p" size="xxs" className="text-gray-400 py-2 self-start">
              By signing up, you agree to Simmr's{" "}
              <a href="https://simmr.co/terms" target="_blank" className="link">
                Terms of Service
              </a>{" "}
              and{" "}
              <a className="link" target="_blank" href="http://simmr.co/privacy">
                Privacy Policy
              </a>
            </Text>
            <Button
              onClick={(e) => {
                onSubmit(e);
                mixpanel.track("Event Signup Flow From Popup", { event_id: event.id });
              }}
              variant="standard"
              className="mt-4"
            >
              Join Event
            </Button>
            <Button onClick={() => setShowSignupFlow(false)} variant="outline" className="mt-4">
              Cancel
            </Button>
          </div>
        </BigModal>
      )}
      <YMHPixel />
      <Helmet>
        <meta property="og:title" content={event.name || ""} />
        <meta
          property="og:description"
          content={`Join the Simmr community at ${event.name || ""} ${event.location_description!}`}
        />
        <meta property="og:image" content={eventImgUrl} />
        <meta
          property="og:url"
          content={`${allRoutes.EVENTS.PUBLIC.buildPath({ slug: event.url_slug })}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="min-h-full flex flex-col gap-4 px-6 w-screen bg-[#2A2C38] text-white">
        <div>
          <div className="flex items-center justify-center gap-2 pt-4 mx-8">
            <img className="w-32 sm:w-64" src={simmrLogo} alt="Simmr logo" />
            <h1 className="text-3xl sm:text-6xl tracking-widest font-thin">EVENTS</h1>
          </div>
          {/* <h3 className="px-14 text-md text-center">
        Want to connect with open-minded ENM people in the wild? 😈
      </h3> */}
          <div className="w-5/6 mx-auto flex flex-col items-center">
            <Text as="h3" size={"xl"} weight="thin" className="text-white text-center hidden">
              Connect with open-minded people in the wild! 😈
            </Text>
            <Text as="h3" size={"md"} weight="thin" className="text-white text-center sm:flex">
              Connect with open-minded people in the wild! 😈
            </Text>
          </div>
        </div>
        <div className="flex flex-col gap-4 ">
          <img className="w-[1440px] md:hidden" src={eventImgUrl} />
          <div className="flex flex-col px-6 sm:hidden">
            <Text as="h2" size="md" className="text-white text-center sm:text-start">
              {event.name}
            </Text>
            {/* <h2 className="font-quicksand text-center text-2xl tracking-wider">
            Link up with the Simmr community at
          </h2> */}
            {/* <h2 className="font-quicksand text-center text-2xl font-semibold tracking-wider">
            {event.name}
          </h2> */}
          </div>
        </div>
        <Container size="md">
          <div className="flex flex-col md:bg-[#373A49]  text-xl rounded-lg md:flex-row md:gap-4 md:items-center md:justify-between">
            <div className="md:w-1/2 md:p-6 flex flex-col gap-4">
              <div className="flex-col px-6 hidden sm:flex">
                <Text as="h2" size="md" className="text-white text-center">
                  {event.name}
                </Text>
                {/* <h2 className="font-quicksand text-center text-2xl tracking-wider">
            Link up with the Simmr community at
          </h2> */}
                {/* <h2 className="font-quicksand text-center text-2xl font-semibold tracking-wider">
            {event.name}
          </h2> */}
              </div>
              <p>&#128197; Date: {formatDate(event.start!)}</p>
              <p>&#128205; Location: {event.location_description}</p>
              <div className="flex items-center gap-2">
                {event.event_attendees.slice(0, 3).map(({ user_summary }, i) => (
                  <Avatar
                    key={i}
                    slug={user_summary?.slug!}
                    className="border-2 border-white first:ml-0 -ml-4 inline-block"
                    tailwindSize="8"
                  />
                ))}
                <p className="text-lg text-[#FE635F]">
                  {event.event_attendees.length < 25 ? "25" : event.event_attendees.length}+ members
                  attending
                </p>
              </div>
              <div className="relative">
                <div
                  className={clsx("overflow-hidden", expanded ? "line-clamp-none" : "line-clamp-3")}
                >
                  {event.description?.split("\n").map((line, i) => (
                    <Linkify
                      options={{
                        target: "_blank",
                        className: "whitespace-pre-line link font-semibold",
                      }}
                      as="p"
                      key={i}
                    >
                      {line}
                      {"\u00A0"}
                    </Linkify>
                  ))}
                </div>
                <a
                  className="cursor-pointer inline ml-auto text-bold text-[#FE635F]"
                  onClick={() => {
                    setExpanded(!expanded);
                    mixpanel.track("Event Description Expanded", { event_id: event.id });
                  }}
                >
                  {expanded ? "Show Less" : "Show More"}
                </a>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  variant="standard"
                  // size="md"
                  className="w-2/3 sm:w-2/3 lg:w-2/3 mt-5"
                  onClick={openModal}
                >
                  JOIN THE EVENT
                </Button>
              </div>
            </div>
            <img className="hidden md:flex md:w-1/2 rounded-r-lg" src={eventImgUrl} />
          </div>
        </Container>
        <Container size="md" className="flex flex-col items-center">
          <Text as="h3" size="lg" className="text-white text-center mt-6 mb-4 hidden sm:flex">
            New to Simmr?
          </Text>
          <Text as="h3" size="md" className="text-white text-center mt-6 mb-4 sm:hidden">
            New to Simmr?
          </Text>
          <SingleFeatureWidget imgSrc={homescreenConnect} right={false}>
            <div className="space-y-4 max-w-md">
              <Text as="h3" weight="semibold" className="text-white leading-tight mb-2">
                Nope. Not "just another dating app"
              </Text>
              <div className="space-y-2">
                <Text as="p" size="sm" weight="regular" className="leading-tight inline">
                  Simmr is where{" "}
                  <span className="bg-[#FD6461] px-2">
                    trust meets connection for the ENM community.
                  </span>
                </Text>
                <Text as="p" size="sm" weight="regular" className="leading-snug block">
                  With built-in verification and vouching, you can focus on staying in touch,
                  finding events, and meeting new people who truly get you. It's more than
                  connecting; it's about belonging
                </Text>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  variant="standard"
                  // size="md"
                  className="w-2/3 mt-5"
                  onClick={openModal}
                >
                  JOIN THE EVENT
                </Button>
              </div>
            </div>
          </SingleFeatureWidget>
          <SingleFeatureWidget imgSrc={homescreenHow} right={true}>
            <div className="space-y-6 max-w-xl">
              {/* Header */}
              <Text as="h3" weight="semibold" className="text-white leading-tight mb-2 ml-10">
                How Simmr Works 🔥
              </Text>

              {/* Features List */}
              <ul className="space-y-6">
                <li className="flex items-center space-x-3">
                  <span className="text-2xl">😈</span>
                  <div>
                    <Text as="p" size="sm" weight="regular" className="leading-tight inline">
                      <span className="font-semibold">Create a profile</span>{" "}
                      <span className="text-white">
                        that represents you and link with your partner's profile for a shared
                        experience.
                      </span>
                    </Text>
                  </div>
                </li>
                <li className="flex items-center space-x-3">
                  <span className="text-2xl">🧡</span>
                  <div>
                    <Text as="p" size="sm" className="leading-tight">
                      <span className="font-semibold">Add friends and matches</span>{" "}
                      <span className="text-white">
                        —build your community by connecting with verified people who understand and
                        celebrate ENM.
                      </span>
                    </Text>
                  </div>
                </li>
                <li className="flex items-center space-x-3">
                  <span className="text-2xl">🎉</span>
                  <div>
                    <Text as="p" size="sm" className="leading-tight">
                      <span className="font-semibold">Attend exclusive ENM events</span>{" "}
                      <span className="text-white">
                        or create your own. Connect with like-minded people in your area or around
                        the world.
                      </span>
                    </Text>
                  </div>
                </li>
              </ul>

              {/* Call to Action */}
              {/* <a href="https://simmr.typeform.com/launch"> */}
              <Button variant="standard" className="mt-4" onClick={openModal}>
                JOIN SIMMR NOW
              </Button>
              {/* </a> */}
            </div>
          </SingleFeatureWidget>
          <SingleFeatureWidget imgSrc={discovery} right={false}>
            {/* <H3 className="text-primary-content normal-case">
                Connect with real, verified members
              </H3> */}
            <Text as="h3" weight="semibold" className="text-white leading-tight mb-2">
              Join Thousands of Verified Members
            </Text>
            <Text as="p" size="sm" weight="regular" className="leading-tight inline">
              <span className="font-semiboldd ">
                No bots, no fakes—just real people who value genuine connections.
              </span>{" "}
              Every Simmr member goes through a verification process to keep our community authentic
              and trusted.{" "}
            </Text>
            <div className="flex items-center justify-center">
              <Button
                variant="standard"
                // size="md"
                className="w-2/3 mt-5"
                onClick={openModal}
              >
                JOIN THE EVENT
              </Button>
            </div>
          </SingleFeatureWidget>
        </Container>
      </div>
    </>
  );
};

export default PublicEventsDetailT;

import { ProfileList, ProfileListItem, ProfileListProps } from "../../../widgets/profile-list";
import { classNames, filterNulls } from "shared/dist/util";
import { useCallback, useEffect, useMemo, useState } from "react";

import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { SimmerZeroScreen, ZeroScreen } from "../../../widgets/zero-screens";
import { allRoutes } from "../../../util/routes";
import { navHeaderState } from "shared-web-react/dist/widgets/nav-stack-view";
import { useMyId } from "shared/dist/auth-data";
import { useMyVouches559Query, useMyVouchesQuery } from "shared/dist/__generated__/components";
import { useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useMyInfo, useMySlug } from "../../bio/bio-settings";
import { ErrorBoundary } from "react-error-boundary";

export function MyVouches(): React.JSX.Element {
  const [hasError, setHasError] = useState(false);
  const my_id = useMyId();
  const { slug: my_slug } = useMyInfo();
  const navigate = useNavigate();
  const { slug } = useTypedParams(allRoutes.PROFILE.VOUCHES);
  const isMyPage = slug === my_slug;

  // Use try-catch to handle potential errors
  const myvouches = useMyVouchesQuery({
    variables: { my_id: my_id! },
    onError: (error) => {
      console.error("Error fetching vouches:", error);
      setHasError(true);
    },
  });

  const { data, loading, error } = useMyVouches559Query({
    variables: { slug: slug ?? "" },
    onError: (error) => {
      console.error("Error fetching vouches 555:", error);
      setHasError(true);
    },
  });

  // Handle errors from queries
  useEffect(() => {
    if (error) {
      console.error("Query error:", error);
      setHasError(true);
    }
  }, [error]);

  const mkRoute: ProfileListProps["mkRoute"] = useCallback(
    ({ slug, ...other }: ProfileListItem) =>
      slug
        ? {
            to: allRoutes.PROFILE.buildPath({ slug }),
            state: navHeaderState("Back to my likes"),
          }
        : null,
    []
  );

  const items: Array<ProfileListItem> = useMemo(() => {
    if (!data?.vouches) return [];

    try {
      return filterNulls(
        data.vouches.map((v) => {
          if (!v) return null;
          return {
            screenName: v.author_summary?.screen_name || "",
            slug: v.author_summary?.slug || null,
          };
        })
      );
    } catch (e) {
      console.error("Error processing vouches data:", e);
      setHasError(true);
      return [];
    }
  }, [data]);

  // If there's an error, show a user-friendly message
  if (hasError) {
    return (
      <div className="flex flex-col h-full w-full overflow-hidden items-stretch px-4 text-white">
        <SimmerZeroScreen
          header="Something went wrong"
          text="We couldn't load the vouches. Please try again later."
          btnText="Go back"
          clickHandler={() => {
            navigate(-1);
          }}
        />
      </div>
    );
  }

  if (loading) return <SpinnerCentered />;

  return items.length === 0 ? (
    <div className="flex flex-col h-full w-full overflow-hidden items-stretch px-4 text-white">
      {isMyPage ? (
        <SimmerZeroScreen
          header="No vouches yet"
          text="Vouches are a way to show appreciation for someone's work. When someone vouches for you, it will show up here."
          btnText="Discover new people"
          clickHandler={() => {
            navigate(allRoutes.DISCOVERY.buildPath({}));
          }}
        />
      ) : (
        <SimmerZeroScreen
          header="No vouches yet"
          text="This user hasn't received any vouches yet. Check back later!"
          btnText="Discover new people"
          clickHandler={() => {
            navigate(allRoutes.DISCOVERY.buildPath({}));
          }}
        />
      )}
    </div>
  ) : (
    <ErrorBoundary
      fallback={
        <div className="flex flex-col h-full w-full overflow-hidden items-stretch px-4 text-white">
          <SimmerZeroScreen
            header="Something went wrong"
            text="We couldn't display the vouches. Please try again later."
            btnText="Go back"
            clickHandler={() => {
              navigate(-1);
            }}
          />
        </div>
      }
    >
      <div
        className={classNames(
          "flex justify-start flex-col join join-vertical gap-4 h-full relative"
        )}
      >
        <div className={classNames("p-4 pb-16 max-h-full overflow-y-auto")}>
          <ProfileList
            profiles={items}
            mkRoute={mkRoute}
            columnCountOverride={1}
            className="text-white"
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}

import { ObPageProps, ObPagePropsWithNext } from "../../screens/onboarding/widgets";
import React, { useCallback, useRef, useState } from "react";
import { faCircleCheck, faExclamationTriangle, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useBirthdayQuery, useUpdateBirthdayMutation } from "shared/dist/__generated__/components";

import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useMyId } from "shared/dist/auth-data";
import { useRefetchMyBioSummary } from "../../screens/user-page/common";
import { Text } from "../../components/atoms/Text";

export function validateBirthday(dt: DateTime, setError?: (e: string | null) => void) {
  const over18Years = DateTime.now().minus({ years: 18 }) > dt;
  setError?.(over18Years ? null : "Sorry, you must be over 18 to join Candid");
  return over18Years;
}

export type BirthdayInputProps = {
  loading?: boolean;
  className?: string;
  dirty?: boolean;
} & React.HTMLProps<HTMLInputElement>;

export function BirthdayInput({
  loading,
  className,
  onBlur,
  onChange,
  dirty,
  ...props
}: BirthdayInputProps): React.JSX.Element {
  const [error, setError] = React.useState<null | string>(null);
  return (
    <div className="BirthdayInput form-control w-full flex-1">
      <label className="w-full join">
        <input
          className={`join-item input input-bordered flex-1 text-left align-left focus:outline-none min-w-0 ${className}`}
          type="date"
          min={DateTime.now().minus({ years: 105, days: 1 }).toISODate() ?? undefined}
          onChange={(e) => {
            const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
            if (!dt) return;
            const valid = validateBirthday(dt, setError);
            if (valid) onChange?.(e);
          }}
          onBlur={(e) => {
            const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
            if (!dt) return;
            const valid = validateBirthday(dt, setError);
            if (valid) onBlur?.(e);
          }}
          {...props}
        />
        <button disabled={loading || !dirty} className={clsx("btn join-item btn-primary")}>
          <FontAwesomeIcon
            icon={loading ? faSpinner : error ? faExclamationTriangle : faCircleCheck}
            className={clsx(!dirty && "text-gray-300")}
            spin={loading}
            fixedWidth
          />
        </button>
      </label>
      <span className="label-text-alt text-error">{error ?? "\u00A0"}</span>
    </div>
  );
}

export function BirthdayPicker(): React.JSX.Element {
  const my_id = useMyId()!;
  const refetchBio = useRefetchMyBioSummary();
  const [saveMutation, saveMutationResult] = useUpdateBirthdayMutation();
  const { data, loading } = useBirthdayQuery({
    variables: { user_id: my_id },
  });
  const [inputDate, setInputDate] = React.useState<DateTime | null>(null);
  const [error, setError] = React.useState<null | string>(null);
  const [dirty, setDirty] = React.useState(false);
  const [showAgeState, setShowAgeState] = React.useState(false);
  const dbDate = React.useMemo(
    () => (data?.users_by_pk?.birthday ? DateTime.fromISO(data.users_by_pk.birthday) : undefined),
    [data?.users_by_pk?.birthday]
  );
  // const addToast = useAddToast();
  const save = React.useCallback(
    async (dt: DateTime | null, display: boolean) => {
      if (!dt) return;
      const valid = validateBirthday(dt, setError);
      console.log("🚀 - file: birthday-picker.tsx:96 - dt:", dt, valid);
      if (!valid) return;
      saveMutation({
        variables: {
          display,
          dt: dt.toISODate(),
          user_id: my_id,
        },
      })
        // .then(() => addToast({ unitary: true, content: "Saved!" }))
        .then(refetchBio);
    },
    [saveMutation, inputDate, setError]
  );

  const anyLoading = loading || saveMutationResult.loading;
  const displayDate = inputDate?.toISODate?.() ?? dbDate?.toISODate();
  return (
    <div>
      <div>
        <label className="label">Birthday</label>
      </div>
      <div
        className={clsx(
          "flex justify-stretch w-full items-stretch mb-2",
          "flex-col max-lg:space-y-2 ",
          "lg:flex-row lg:items-start lg:justify-start lg:space-x-2 "
        )}
      >
        <BirthdayInput
          loading={anyLoading}
          disabled={anyLoading}
          dirty={dirty}
          // placeholder={"1995-01-01"}
          // defaultValue={dbDate?.toISODate() ?? "1990-01-01"}
          value={displayDate ?? "1990-01-01"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
            if (!dt) return;
            setInputDate(validateBirthday(dt) ? dt : DateTime.now().minus({ years: 18, days: 1 }));
            setDirty(true);
            // save(dt, showAgeState);
          }}
          onBlur={(e) => {
            const dt = e.target.value ? DateTime.fromISO(e.target.value) : null;
            setInputDate(dt);
            save(dt, showAgeState);
            setDirty(false);
          }}
        />
        <div className="flex-1 form-control">
          <label className="label text-left justify-start pl-0">
            <input
              type="checkbox"
              checked={showAgeState}
              onChange={(evt) => {
                setShowAgeState(evt.target.checked);
                save(inputDate, evt.target.checked);
              }}
              className="checkbox"
            />
            <span className="ml-1 label-text">Show Age on Profile?</span>
          </label>
        </div>
      </div>
    </div>
  );
}

export function ObBirthdayPicker({ setDisableButton }: ObPageProps): React.JSX.Element {
  const [month, setMonth] = useState(["", ""]);
  const [day, setDay] = useState(["", ""]);
  const [year, setYear] = useState(["", "", "", ""]);
  const [error, setError] = useState<string | null>(null);
  const [loadedFromDb, setLoadedFromDb] = useState(false);
  const [updateBirthdayMutation] = useUpdateBirthdayMutation();
  const [isValid, setIsValid] = useState(false);

  const my_id = useMyId()!;
  const { data } = useBirthdayQuery({
    fetchPolicy: "cache-first",
    variables: { user_id: my_id },
  });

  const dbDate = data?.users_by_pk?.birthday ? DateTime.fromISO(data.users_by_pk.birthday) : null;

  const refs = useRef<HTMLInputElement[]>([]);

  // Initial load from DB
  React.useEffect(() => {
    console.log("Initial DB load check:", { loadedFromDb, dbDate: dbDate?.toISO() });

    if (loadedFromDb) return;
    if (!dbDate) return;

    const monthStr = dbDate.month.toString().padStart(2, "0");
    const dayStr = dbDate.day.toString().padStart(2, "0");
    const yearStr = dbDate.year.toString();

    console.log("Loading from DB:", { monthStr, dayStr, yearStr });

    setMonth([monthStr[0], monthStr[1]]);
    setDay([dayStr[0], dayStr[1]]);
    setYear([yearStr[0], yearStr[1], yearStr[2], yearStr[3]]);
    setLoadedFromDb(true);

    console.log("Loaded from DB, setting loadedFromDb to true");
  }, [dbDate, loadedFromDb]);

  const validateAndUpdateButton = () => {
    const monthStr = month.join("");
    const dayStr = day.join("");
    const yearStr = year.join("");

    console.log("Direct validation check:", { monthStr, dayStr, yearStr });

    // Check if we have a complete date
    if (monthStr.length !== 2 || dayStr.length !== 2 || yearStr.length !== 4) {
      console.log("Direct validation: Incomplete date");
      setDisableButton(true);
      return false;
    }

    // Parse the date
    const birthday = DateTime.fromFormat(`${monthStr}/${dayStr}/${yearStr}`, "MM/dd/yyyy");
    if (!birthday.isValid) {
      console.log("Direct validation: Invalid date");
      setDisableButton(true);
      return false;
    }

    // Check age
    const now = DateTime.now();
    const age = now.diff(birthday, "years").years;
    if (age < 18) {
      console.log("Direct validation: Under 18");
      setDisableButton(true);
      return false;
    }

    console.log("Direct validation: Valid date, enabling button");
    setError(null);
    setDisableButton(false);
    setIsValid(true);
    return true;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    setState: React.Dispatch<React.SetStateAction<string[]>>,
    state: string[],
    nextRefIndex?: number,
    prevRefIndex?: number
  ) => {
    // Get just the last character typed, regardless of what was there before
    const value = e.target.value.slice(-1).replace(/\D/g, "");
    const newState = [...state];

    console.log("Input change:", { value, index, state: newState.join("") });

    // Always replace the current digit with the new one if it's a number
    if (value) {
      newState[index] = value;
      setState(newState);
      console.log("Updated state:", newState.join(""));

      // Check if this completes a valid date entry
      const isMonth = state === month;
      const isDay = state === day;
      const isYear = state === year;

      // If we're on the last digit of the year, check if we have a complete date
      if (isYear && index === 3 && value) {
        const completeMonth = month.join("").length === 2;
        const completeDay = day.join("").length === 2;
        const completeYear = [...year.slice(0, 3), value].join("").length === 4;

        if (completeMonth && completeDay && completeYear) {
          console.log("Potentially complete date entered, triggering validation");
          // Run validation directly
          setTimeout(() => validateAndUpdateButton(), 0);
        }
      }

      // Move to next input if available and select its content
      if (nextRefIndex !== undefined && refs.current[nextRefIndex]) {
        refs.current[nextRefIndex].focus();
        refs.current[nextRefIndex].select();
      }
    } else {
      // Clear current input if it's not a number
      newState[index] = "";
      setState(newState);
      console.log("Cleared input, new state:", newState.join(""));
    }
  };

  // Handle keydown for backspace
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
    state: string[],
    prevRefIndex?: number
  ) => {
    if (e.key === "Backspace") {
      if (state[index] === "") {
        // If current input is empty and backspace is pressed, go to previous input
        if (prevRefIndex !== undefined && refs.current[prevRefIndex]) {
          refs.current[prevRefIndex].focus();
        }
      }
    }
  };

  // Validation and update effect
  React.useEffect(() => {
    console.log("Validation effect triggered");

    // Skip validation if no input has been entered yet
    const hasUserInput =
      month.some((m) => m !== "") || day.some((d) => d !== "") || year.some((y) => y !== "");
    if (!hasUserInput && !loadedFromDb) {
      console.log("Skipping validation - no user input and not loaded from DB");
      return;
    }

    // Use the direct validation function
    const isValid = validateAndUpdateButton();

    // Only save to DB if validation passed and we've loaded initial data or user has entered a complete date
    if (isValid) {
      const monthStr = month.join("");
      const dayStr = day.join("");
      const yearStr = year.join("");
      const birthday = DateTime.fromFormat(`${monthStr}/${dayStr}/${yearStr}`, "MM/dd/yyyy");

      // Only update if the date is different from what's in the DB
      if (dbDate && birthday.toISODate() === dbDate.toISODate()) {
        console.log("Date matches DB date, not saving");
        return;
      }

      console.log("Saving valid date to DB");
      updateBirthdayMutation({
        variables: {
          display: true,
          dt: birthday.toISODate(),
          user_id: my_id,
        },
      });
    }
  }, [month, day, year, loadedFromDb, dbDate, updateBirthdayMutation, my_id, setDisableButton]);
  return (
    <div className="flex flex-col px-6">
      <div className="flex items-center justify-center space-x-2">
        {/* Month */}
        <div className="inline-flex space-x-1">
          {month.map((_, i) => (
            <span key={`month-${i}`} className="border-b border-white">
              <input
                ref={(el) => {
                  refs.current[i] = el!;
                }}
                type="text"
                maxLength={1}
                inputMode="numeric"
                placeholder="M"
                className="w-8 h-10 text-center text-white placeholder-white bg-transparent focus:outline-none"
                value={month[i]}
                onFocus={(e) => e.target.select()} // Add this line
                onChange={(e) =>
                  handleInputChange(
                    e,
                    i,
                    setMonth,
                    month,
                    i === 1 ? 2 : i + 1,
                    i === 0 ? undefined : i - 1
                  )
                }
                onKeyDown={(e) => handleKeyDown(e, i, month, i === 0 ? undefined : i - 1)}
              />
            </span>
          ))}
        </div>

        <span className="text-white">/</span>

        {/* Day */}
        <div className="inline-flex space-x-1">
          {day.map((_, i) => (
            <span key={`day-${i}`} className="border-b border-white">
              <input
                ref={(el) => {
                  refs.current[2 + i] = el!;
                }}
                type="text"
                maxLength={1}
                inputMode="numeric"
                onFocus={(e) => e.target.select()} // Add this line
                placeholder="D"
                className="w-8 h-10 text-center text-white placeholder-white bg-transparent focus:outline-none"
                value={day[i]}
                onChange={(e) =>
                  handleInputChange(e, i, setDay, day, i === 1 ? 4 : 3 + i, i === 0 ? 1 : 2)
                }
                onKeyDown={(e) => handleKeyDown(e, i, day, i === 0 ? 1 : 2)}
              />
            </span>
          ))}
        </div>

        <span className="text-white">/</span>

        {/* Year */}
        <div className="inline-flex space-x-1">
          {year.map((_, i) => (
            <span key={`year-${i}`} className="border-b border-white">
              <input
                ref={(el) => {
                  refs.current[4 + i] = el!;
                }}
                type="text"
                maxLength={1}
                inputMode="numeric"
                onFocus={(e) => e.target.select()} // Add this line
                placeholder="Y"
                className="w-8 h-10 text-center text-white placeholder-white bg-transparent focus:outline-none"
                value={year[i]}
                onChange={(e) =>
                  handleInputChange(
                    e,
                    i,
                    setYear,
                    year,
                    i === 3 ? undefined : 5 + i,
                    i === 0 ? 3 : 4 + i - 1
                  )
                }
                onKeyDown={(e) => handleKeyDown(e, i, year, i === 0 ? 3 : 4 + i - 1)}
              />
            </span>
          ))}
        </div>
      </div>

      <p className="text-gray-500 text-xs mt-2">
        Your profile will display your age, not your birthdate.
      </p>

      {error && (
        <p className="text-red-500 text-sm mt-2 font-medium">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
          {error}
        </p>
      )}
    </div>
  );
}

import { Size } from "./routes";

/**
 * Utility to determine the most appropriate image size based on the context
 * This helps ensure we're using optimized images across the application
 */

// Define standard viewport breakpoints for responsive images
export const VIEWPORT_BREAKPOINTS = {
  MOBILE: 640, // Small mobile devices
  TABLET: 768, // Tablets and large phones
  DESKTOP: 1024, // Desktop and laptops
  LARGE: 1280, // Large desktop screens
};

// Define context types for better type safety
export type ImageContext = "AVATAR" | "PROFILE_GRID" | "NEWS_FEED" | "PROFILE" | "CONNECTION";
export type ImageVariant =
  | "DEFAULT"
  | "SMALL"
  | "LARGE"
  | "MOBILE"
  | "DESKTOP"
  | "ZOOMED"
  | "THUMBNAIL"
  | "ICON";

// Define the optimal sizes for different contexts with proper typing
export const IMAGE_SIZE_MAP: Record<ImageContext, Record<string, Size>> = {
  // Avatar images (profile pictures)
  AVATAR: {
    DEFAULT: "avatar",
    SMALL: "avatar",
    LARGE: "avatar",
  },

  // Profile grid items
  PROFILE_GRID: {
    DEFAULT: "md", // 640px - good balance for grid items
    MOBILE: "md", // 640px for mobile
    DESKTOP: "md", // 640px for desktop
  },

  // News feed images
  NEWS_FEED: {
    DEFAULT: "md", // 640px - good for feed items
    MOBILE: "md", // 640px for mobile
    ZOOMED: "lg", // 1280px for zoomed view
  },

  // Full profile images
  PROFILE: {
    DEFAULT: "lg", // 1280px for profile pages
    MOBILE: "md", // 640px for mobile profile pages
    THUMBNAIL: "thumb", // 128px for thumbnails
  },

  // Connection images (messaging, friends list)
  CONNECTION: {
    DEFAULT: "avatar", // avatar size for connections
    ICON: "thumb", // 128px for small icons
    SMALL: "thumb", // 128px for small connection images
    LARGE: "md", // 640px for large connection images
  },
};

/**
 * Determines the optimal image size based on the context and viewport
 * @param context The context where the image is being displayed
 * @param variant Optional variant for special cases
 * @param viewportWidth Optional viewport width to override automatic detection
 * @returns The optimal image size for the given context
 */
export function getOptimalImageSize(
  context: ImageContext,
  variant?: ImageVariant,
  viewportWidth?: number
): Size {
  // Default to window width if available, otherwise use a reasonable default
  const width =
    viewportWidth ||
    (typeof window !== "undefined" ? window.innerWidth : VIEWPORT_BREAKPOINTS.TABLET);

  // If a specific variant is provided and exists in the context map, use it
  if (variant && IMAGE_SIZE_MAP[context][variant]) {
    return IMAGE_SIZE_MAP[context][variant];
  }

  // Otherwise determine based on viewport width
  if (width <= VIEWPORT_BREAKPOINTS.MOBILE) {
    return IMAGE_SIZE_MAP[context].MOBILE || IMAGE_SIZE_MAP[context].DEFAULT;
  } else if (width <= VIEWPORT_BREAKPOINTS.TABLET) {
    return IMAGE_SIZE_MAP[context].DEFAULT;
  } else {
    return IMAGE_SIZE_MAP[context].DESKTOP || IMAGE_SIZE_MAP[context].DEFAULT;
  }
}

/**
 * React hook to get the optimal image size for a given context
 * @param context The context where the image is being displayed
 * @param variant Optional variant for special cases
 * @returns The optimal image size
 */
export function useOptimalImageSize(context: ImageContext, variant?: ImageVariant): Size {
  return getOptimalImageSize(context, variant);
}

import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import {
  Media_Upload_Statuses_Enum,
  useProcessImageInDevMutation,
  useUploadMediaMutation,
  useUploadStatusLazyQuery,
} from "shared/dist/__generated__/components";
import { PreviewComponentProps, getUploadPreviewForBatchItemsMethod } from "@rpldy/upload-preview";
import Uploady, {
  useAbortAll,
  useBatchAddListener,
  useBatchFinalizeListener,
  useItemProgressListener,
  useRequestPreSend,
  useUploady,
} from "@rpldy/uploady";
import { faCheck, faSpinner, faUpload, faXmark } from "@fortawesome/pro-solid-svg-icons";

import React from "react";
import { asUploadButton } from "@rpldy/upload-button";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useIsDev } from "shared/dist/util/env";
import { createPortal } from "react-dom";
import { Text } from "../components/atoms/Text";
import { icon } from "@fortawesome/fontawesome-svg-core";

export type MediaOptions = {
  is_private?: boolean;
  is_nsfw?: boolean;
  exclude_from_bio?: boolean;
  exclude_from_feed?: boolean;
};

type UploaderCommonProps = {
  btnContent?: string | React.JSX.Element | null;
  btnClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
  iconClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
  iconOverride?: FontAwesomeIconProps["icon"];
  showPreviewConfirmation?: boolean;
  disallowMultiple?: boolean;
};

export type ImageUploaderProps = {
  onStart?: () => void;
  onDismiss?: () => void;
  onError: () => void;
  onComplete: (s: string) => void;
  onUploadIdReceived?: (s: string) => void;
  mediaOptions?: MediaOptions;
} & UploaderCommonProps;

type UploadButtonProps = {
  progress?: number; // 0-100
  processing?: boolean;
  loading?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  UploaderCommonProps;
const UploadButton = asUploadButton(
  React.forwardRef<HTMLButtonElement>(
    (
      {
        processing,
        btnClassName,
        iconClassName,
        iconOverride,
        loading,
        btnContent,
        progress,
        showPreviewConfirmation,
        ...props
      }: UploadButtonProps,
      ref
    ) => (
      <button
        {...props}
        ref={ref}
        style={{ cursor: "pointer" }}
        disabled={processing || loading}
        className={classNames("btn", btnClassName)}
      >
        {/* <span className="w-8" /> */}
        <span
          className={clsx("inline-block relative", btnContent === "Upload Image" ? "hidden" : "")}
          style={{
            display: iconClassName == "hidden" ? "none" : "flex",
          }}
        >
          <div className={classNames("absolute w-full h-full justify-center items-center flex")}>
            <FontAwesomeIcon
              className={classNames(loading ? "hidden" : "", iconClassName)}
              spin={processing}
              icon={processing ? faSpinner : (iconOverride ?? faUpload)}
              fixedWidth
            />
          </div>
          <div className="flex h-full w-full justify-center items-center">
            <div
              className={classNames(
                // "mr-2 ",
                // "mr-2 ",
                loading ? "opacity-100" : "opacity-0",
                "radial-progress "
              )}
              style={
                {
                  "--value": progress,
                  "--size": "1.5rem",
                  "--thickness": "4px",
                } as any
              }
            />
          </div>
        </span>
        {btnContent ?? "Upload Files"}
      </button>
    )
  )
);

function ImageUploadButton({
  btnClassName,
  btnContent,
  iconClassName,
  iconOverride,
  loading,
  mediaOptions,
  onError,
  onFinalize,
  onStart,
  processing,
  setUploadId,
  showPreviewConfirmation,
}: {
  loading: boolean;
  onFinalize: () => void;
  onError: () => void;
  setUploadId: (s: string) => void;
  onStart: () => void;
  mediaOptions?: MediaOptions;
  processing?: boolean;
} & UploaderCommonProps): React.JSX.Element {
  const progressData = useItemProgressListener();
  const progressVal = progressData?.completed ?? 0;
  const [uploadMedia] = useUploadMediaMutation();
  useBatchFinalizeListener(async (batch) => {
    // console.log("🚀 ~ file: images.tsx ~ line 14 ~ useBatchFinalizeListener ~ batch", batch);
    onFinalize();
  });
  useRequestPreSend(async ({ items, options }) => {
    if (items.length !== 1) {
      return false;
    }
    items[0].file.type;
    // console.log("🚀 ~ file: images.tsx ~ line 10 ~ useRequestPreSend ~ items", items);
    try {
      onStart();
      const signedUrl = await uploadMedia({
        variables: {
          content_type: items[0].file.type,
          ...mediaOptions,
        },
      });
      const url = signedUrl?.data?.lm_upload_media?.signed_url_data.presigned_url;
      // console.log("🚀 ~ file: images.tsx ~ line 23 ~ useRequestPreSend ~ url", url);
      if (!signedUrl?.data?.lm_upload_media || !url) {
        onError();
        return false;
      }
      setUploadId(signedUrl?.data?.lm_upload_media?.media_upload_id!);
      return {
        options: {
          destination: { url, method: "PUT" },
          sendWithFormData: false,
        },
      };
    } catch (err) {
      console.error("Error uploading: ", err);
      onError();
      return false;
    }
  });
  return (
    <UploadButton
      extraProps={{
        btnClassName,
        iconClassName,
        iconOverride,
        btnContent,
        className: "ImageUploadButton btn",
        // loading: true,
        // progress: 50,
        loading,
        progress: progressVal,
        processing,
        showPreviewConfirmation,
      }}
    />
  );
}

// url?: string;
// filesParamName?: string;
// params?: Record<string, unknown>;
// headers?: Record<string, unknown>;
// method?: string;

function UploadStatus(): React.JSX.Element {
  const progressData = useItemProgressListener();
  // // console.log(
  // //   "🚀 ~ file: images.tsx ~ line 65 ~ UploadStatus ~ progressData",
  // //   progressData
  // // );
  const val = progressData?.completed ?? 0;
  return (
    <div className="radial-progress mx-4" style={{ "--value": val } as any}>
      {`${Math.floor(val)}%`}
    </div>
  );
}

export function ImageUploader({
  btnClassName,
  btnContent,
  iconClassName,
  iconOverride,
  mediaOptions,
  showPreviewConfirmation,
  onComplete,
  onDismiss,
  onError,
  onStart,
  onUploadIdReceived,
  disallowMultiple,
}: ImageUploaderProps): React.JSX.Element {
  const [uploadId, setUploadId] = React.useState<null | string>(null);
  const [loadingAny, setLoadingAny] = React.useState(false);
  const [finishedUploading, setFinishedUploading] = React.useState(false);
  const addToast = useAddToast();
  const [devForceProcess] = useProcessImageInDevMutation();
  const [getUploadStatus, { data, loading: uploadStatusLoading, stopPolling }] =
    useUploadStatusLazyQuery();
  const isDev = useIsDev();
  React.useEffect(() => {
    // console.log("🚀 ~ file: images.tsx ~ line 110 ~ status", data?.media_uploads_by_pk?.status);
  }, [data]);
  React.useEffect(() => {
    if (!uploadId) {
      return;
    }
    if (!finishedUploading) {
      return;
    }
    devForceProcess({ variables: { media_upload_id: uploadId } });
    getUploadStatus({
      pollInterval: 1000,
      variables: { media_upload_id: uploadId },
      // onCompleted: (data) => {
      // //   console.log("🚀 ~ file: images.tsx ~ line 114 ~ React.useEffect ~ data", data);
      //   if (data.media_uploads_by_pk?.status === Media_Upload_Statuses_Enum.V1Processed) {
      //     stopPolling();
      //     setFinishedUploading(false);
      //     setLoadingAny(false);
      //     setUploadId(null);
      //     onComplete(uploadId);
      //     onDismiss?.();
      //   }
      // },
    });
    const errorTimeout = setTimeout(() => {
      addToast({
        content: "Failed to upload image, please try again.",
        color: "error",
      });
      setFinishedUploading(false);
      setLoadingAny(false);
      setUploadId(null);
      onComplete(uploadId);
      onDismiss?.();
    }, 1000 * 60);
    return () => {
      stopPolling();
      errorTimeout && clearTimeout(errorTimeout);
    };
  }, [finishedUploading, uploadId, stopPolling]);
  React.useEffect(() => {
    if (
      uploadId &&
      (data?.media_uploads_by_pk?.status === Media_Upload_Statuses_Enum.V2Processed ||
        data?.media_uploads_by_pk?.status === Media_Upload_Statuses_Enum.V1Processed)
    ) {
      // Keep loading state active for a short period to ensure UI has time to update
      setTimeout(() => {
        stopPolling();
        setFinishedUploading(false);
        setLoadingAny(false);
        setUploadId(null);
        onComplete(uploadId);
        onDismiss?.();
      }, 500);
    }
  }, [
    uploadId,
    data?.media_uploads_by_pk?.status,
    stopPolling,
    setFinishedUploading,
    setLoadingAny,
    setUploadId,
    onComplete,
    onDismiss,
  ]);
  return (
    <React.Fragment>
      {onDismiss && (
        <button
          className="btn btn-sm btn-circle absolute right-2 top-2"
          disabled={loadingAny}
          onClick={onDismiss}
        >
          x
        </button>
      )}
      {/* {loadingAny ? ( <SpinnerFullScreen />) : ( */}
      <React.Fragment>
        <Uploady
          fileFilter={(file) => typeof file !== "string" && "size" in file && file.size <= 10e6}
          autoUpload={!showPreviewConfirmation}
          clearPendingOnAdd
          multiple={!disallowMultiple && !showPreviewConfirmation}
          accept=".png,.jpg,.jpeg,.heic,.heif"
        >
          <ImageUploadButton
            {...{
              btnClassName,
              iconClassName,
              iconOverride,
              btnContent,
              showPreviewConfirmation,
            }}
            loading={loadingAny}
            processing={finishedUploading}
            mediaOptions={mediaOptions}
            onStart={() => {
              setLoadingAny(true);
              onStart?.();
            }}
            onError={onError}
            setUploadId={(id) => {
              setUploadId(id);
              onUploadIdReceived?.(id);
            }}
            onFinalize={() => {
              setFinishedUploading(true);
              // onDismiss();
              setLoadingAny(false);
            }}
          />
          {showPreviewConfirmation ? <UploadConfirmation /> : <></>}
        </Uploady>
      </React.Fragment>
    </React.Fragment>
  );
}

function PreviewComponent({
  url,
  ...props
}: Pick<ImageUploaderProps, "onDismiss"> & PreviewComponentProps): React.JSX.Element {
  const { processPending, clearPending } = useUploady();
  const [processedUrls, setProcessedUrls] = React.useState<Array<string>>([]);
  const abort = useAbortAll();
  const confirmOpts = {
    title: "Upload this image?",
    content: (
      <div className={clsx("flex items-center justify-center")}>
        <img className={clsx("max-h-64 max-w-64")} src={url} alt="" />
      </div>
    ),
    okButtonContent: "Upload",
    onOk: async () => processPending(),
    showDelay: 100,
    cancelButtonContent: "Cancel",
    onCancel: async () => {
      clearPending(), props.onDismiss?.(), abort();
    },
  };
  console.log("🚀 ~ Outside of useEffect: file: images.tsx:364 ~ confirmOpts:", confirmOpts?.title);

  const confirm = useConfirm();
  React.useEffect(() => {
    if (processedUrls.includes(url ?? "")) {
      return;
    }
    confirm(url ? confirmOpts : null);
    setProcessedUrls((processedUrls) => processedUrls.concat(url ?? ""));
  }, [processedUrls, setProcessedUrls, url, confirm, confirmOpts]);
  return <></>;
}

const UploadPreviewOnAdd = getUploadPreviewForBatchItemsMethod(useBatchAddListener);

function UploadConfirmation({
  onDismiss,
}: Pick<ImageUploaderProps, "onDismiss">): React.JSX.Element {
  return (
    <UploadPreviewOnAdd PreviewComponent={PreviewComponent} previewComponentProps={{ onDismiss }} />
  );
}

export function UploadProgress({
  progress,
  state,
  onDismiss,
}: {
  progress: number;
  state: "uploading" | "complete" | "error";
  onDismiss?: () => void;
}) {
  return createPortal(
    <div
      className="fixed inset-0 flex items-center justify-center bg-black/50 z-50"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="bg-[#464752] rounded-lg shadow-xl min-w-[300px] max-w-sm mx-4">
        {state === "uploading" && (
          <div className="p-6 text-center">
            <h3 className="text-lg font-medium mb-4 text-base-100">Uploading...</h3>
            <div className="w-full bg-base-300 rounded-full h-4 mb-4">
              <div
                className="h-4 rounded-full transition-all duration-300 ease-out"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-sm text-base-100/70">{Math.round(progress)}% complete</p>
          </div>
        )}

        {state === "complete" && (
          <div
            className="p-8 text-center cursor-pointer"
            onClick={onDismiss}
            role="button"
            tabIndex={0}
          >
            <h3 className="text-2xl font-semibold text-base-100">All set!</h3>
            <div className="w-20 h-20 rounded-full bg-success flex items-center justify-center mx-auto my-6">
              <FontAwesomeIcon icon={faCheck} className="text-base-100 text-3xl" />
            </div>
            <p className="text-base-100/90 text-lg mb-4">Your post has been added.</p>
            <button className="text-base-100/80 hover:text-base-100 text-sm font-medium">
              View Post
            </button>
            <Text as="p" size="xxs" className="text-base-100/70 cursor-pointer">
              *images may take a moment to load into your profile*
            </Text>
          </div>
        )}

        {state === "error" && (
          <div
            className="p-6 text-center cursor-pointer"
            onClick={onDismiss}
            role="button"
            tabIndex={0}
          >
            <div className="w-16 h-16 rounded-full bg-error flex items-center justify-center mx-auto">
              <FontAwesomeIcon icon={faXmark} className="text-base-100 text-2xl" />
            </div>
            <h3 className="text-xl font-medium text-base-100 mt-4">Upload Failed</h3>
            <p className="text-base-100/70 mt-2">
              There was an error uploading your file. Please try again.
            </p>
          </div>
        )}
      </div>
    </div>,
    document.body
  );
}

// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSpinner, faUpload, faCheck, faXmark } from "@fortawesome/pro-solid-svg-icons";
// import { asUploadButton } from "@rpldy/upload-button";
// import Uploady, {
//   useItemProgressListener,
//   useRequestPreSend,
//   useBatchFinalizeListener,
// } from "@rpldy/uploady";
// import { classNames } from "shared/dist/util";
// import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
// import { useUploadMediaMutation } from "shared/dist/__generated__/components";
// import { createPortal } from "react-dom";
// import { useNavigate } from "react-router-dom";
// import { allRoutes } from "../util/routes";
// import { Text } from "../components/atoms/Text";

// interface FlexibleUploadButtonProps {
//   processing?: boolean;
//   btnClassName?: string;
//   iconClassName?: string;
//   iconOverride?: FontAwesomeIconProps["icon"];
//   loading?: boolean;
//   btnContent?: React.ReactNode;
//   progress?: number;
//   showPreviewConfirmation?: boolean;
//   textOnly?: boolean;
// }

// export type MediaOptions = {
//   is_private?: boolean;
//   is_nsfw?: boolean;
//   exclude_from_bio?: boolean;
//   exclude_from_feed?: boolean;
// };

// export type UploaderCommonProps = {
//   btnContent?: string | React.ReactNode | null;
//   btnClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
//   iconClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
//   iconOverride?: FontAwesomeIconProps["icon"];
//   showPreviewConfirmation?: boolean;
//   disallowMultiple?: boolean;
// };

// export type ImageUploaderProps = {
//   onStart?: () => void;
//   onDismiss?: () => void;
//   onError: () => void;
//   onComplete: (s: string) => void;
//   onUploadIdReceived?: (s: string) => void;
//   mediaOptions?: MediaOptions;
//   textOnly?: boolean;
//   initialFile?: File;
//   overridePopup?: {
//     hide?: boolean;
//     title?: string;
//     subtitle?: string;
//   };
// } & UploaderCommonProps;

// function UploadProgress({
//   progress,
//   state,
//   onDismiss,
// }: {
//   progress: number;
//   state: "uploading" | "complete" | "error";
//   onDismiss?: () => void;
// }) {
//   return createPortal(
//     <div
//       className="fixed inset-0 flex items-center justify-center bg-black/50 z-50"
//       onClick={(e) => e.stopPropagation()}
//     >
//       <div className="bg-[#464752] rounded-lg shadow-xl min-w-[300px] max-w-sm mx-4">
//         {state === "uploading" && (
//           <div className="p-6 text-center">
//             <h3 className="text-lg font-medium mb-4 text-base-100">Uploading...</h3>
//             <div className="w-full bg-base-300 rounded-full h-4 mb-4">
//               <div
//                 className="bg-primary h-4 rounded-full transition-all duration-300 ease-out"
//                 style={{ width: `${progress}%` }}
//               />
//             </div>
//             <p className="text-sm text-base-100/70">{Math.round(progress)}% complete</p>
//           </div>
//         )}

//         {state === "complete" && (
//           <div
//             className="p-8 text-center cursor-pointer"
//             onClick={onDismiss}
//             role="button"
//             tabIndex={0}
//           >
//             <h3 className="text-2xl font-semibold text-base-100">All set!</h3>
//             <div className="w-20 h-20 rounded-full bg-success flex items-center justify-center mx-auto my-6">
//               <FontAwesomeIcon icon={faCheck} className="text-base-100 text-3xl" />
//             </div>
//             <p className="text-base-100/90 text-lg mb-4">Your post has been added.</p>
//             <button className="text-base-100/80 hover:text-base-100 text-sm font-medium">
//               View Post
//             </button>
//             <Text as="p" size="xxs" className="text-base-100/70 cursor-pointer">
//               *images may take a moment to load into your profile*
//             </Text>
//           </div>
//         )}

//         {state === "error" && (
//           <div
//             className="p-6 text-center cursor-pointer"
//             onClick={onDismiss}
//             role="button"
//             tabIndex={0}
//           >
//             <div className="w-16 h-16 rounded-full bg-error flex items-center justify-center mx-auto">
//               <FontAwesomeIcon icon={faXmark} className="text-base-100 text-2xl" />
//             </div>
//             <h3 className="text-xl font-medium text-base-100 mt-4">Upload Failed</h3>
//             <p className="text-base-100/70 mt-2">
//               There was an error uploading your file. Please try again.
//             </p>
//           </div>
//         )}
//       </div>
//     </div>,
//     document.body
//   );
// }

// const FlexibleUploadButton = asUploadButton(
//   React.forwardRef<HTMLButtonElement, FlexibleUploadButtonProps>(
//     (
//       {
//         processing,
//         btnClassName,
//         iconClassName,
//         iconOverride,
//         loading,
//         btnContent,
//         progress,
//         showPreviewConfirmation,
//         textOnly = false,
//         ...props
//       },
//       ref
//     ) => {
//       if (textOnly) {
//         return (
//           <span
//             {...props}
//             ref={ref as React.Ref<HTMLSpanElement>}
//             style={{ cursor: "pointer" }}
//             className={classNames("inline-flex items-center gap-2", btnClassName)}
//           >
//             {loading || processing ? (
//               <FontAwesomeIcon className={iconClassName} spin icon={faSpinner} size="sm" />
//             ) : null}
//             <span className={classNames(loading || processing ? "opacity-50" : "")}>
//               {btnContent ?? "Upload Files"}
//             </span>
//           </span>
//         );
//       }

//       return (
//         <button
//           {...props}
//           ref={ref}
//           style={{ cursor: "pointer" }}
//           disabled={processing || loading}
//           className={classNames("btn btn-primary", btnClassName)}
//         >
//           <span className="inline-block relative">
//             <div className="absolute w-full h-full justify-center items-center flex">
//               <FontAwesomeIcon
//                 className={classNames(loading ? "hidden" : "", iconClassName)}
//                 spin={processing}
//                 icon={processing ? faSpinner : (iconOverride ?? faUpload)}
//                 fixedWidth
//               />
//             </div>
//             <div className="flex h-full w-full justify-center items-center">
//               <div
//                 className={classNames(loading ? "opacity-100" : "opacity-0", "radial-progress")}
//                 style={
//                   {
//                     "--value": progress,
//                     "--size": "1.5rem",
//                     "--thickness": "4px",
//                   } as any
//                 }
//               />
//             </div>
//           </span>
//           {btnContent ?? "Upload Files"}
//         </button>
//       );
//     }
//   )
// );

// interface ImageUploadButtonProps extends FlexibleUploadButtonProps {
//   mediaOptions?: MediaOptions;
//   onError: () => void;
//   onFinalize: () => void;
//   onStart: () => void;
//   setUploadId: (id: string) => void;
//   onProgress: (progress: number) => void;
//   uploadState: "idle" | "uploading" | "complete" | "error";
//   overridePopup?: {
//     hide?: boolean;
//     title?: string;
//     subtitle?: string;
//   };
// }

// function ImageUploadButton({
//   btnClassName,
//   btnContent,
//   iconClassName,
//   iconOverride,
//   loading,
//   mediaOptions,
//   onError,
//   onFinalize,
//   onStart,
//   onProgress,
//   processing,
//   setUploadId,
//   showPreviewConfirmation,
//   textOnly = false,
//   uploadState,
//   overridePopup,
// }: ImageUploadButtonProps) {
//   const progressData = useItemProgressListener();
//   const progressVal = progressData?.completed ?? 0;
//   const [uploadMedia] = useUploadMediaMutation();
//   const navigate = useNavigate();

//   React.useEffect(() => {
//     onProgress(progressVal);
//   }, [progressVal, onProgress]);

//   useBatchFinalizeListener(async () => {
//     onFinalize();
//   });

//   useRequestPreSend(async ({ items }) => {
//     if (items.length !== 1) {
//       return false;
//     }

//     try {
//       onStart();
//       const signedUrl = await uploadMedia({
//         variables: {
//           content_type: items[0].file.type,
//           ...mediaOptions,
//         },
//       });

//       const url = signedUrl?.data?.lm_upload_media?.signed_url_data.presigned_url;

//       if (!signedUrl?.data?.lm_upload_media || !url) {
//         onError();
//         return false;
//       }

//       setUploadId(signedUrl?.data?.lm_upload_media?.media_upload_id);

//       return {
//         options: {
//           destination: { url, method: "PUT" },
//           sendWithFormData: false,
//         },
//       };
//     } catch (err) {
//       console.error("Error uploading: ", err);
//       onError();
//       return false;
//     }
//   });

//   return (
//     <>
//       {uploadState !== "idle" &&  !overridePopup?.hide &&(
//         <UploadProgress
//           progress={progressVal}
//           state={uploadState}
//           onDismiss={() => navigate(allRoutes.me.buildPath({}))}
//         />
//       )}
//       <FlexibleUploadButton
//         extraProps={{
//           btnClassName,
//           iconClassName,
//           iconOverride,
//           btnContent,
//           loading,
//           progress: progressVal,
//           processing,
//           showPreviewConfirmation,
//           textOnly,
//         }}
//       />
//     </>
//   );
// }

// export function ImageUploader({
//   btnClassName,
//   btnContent,
//   iconClassName,
//   iconOverride,
//   mediaOptions,
//   showPreviewConfirmation,
//   onComplete,
//   onDismiss,
//   onError,
//   onStart,
//   onUploadIdReceived,
//   disallowMultiple,
//   textOnly = false,
//   initialFile,
//   overridePopup,
// }: ImageUploaderProps): React.JSX.Element {
//   const [uploadId, setUploadId] = React.useState<null | string>(null);
//   const [loadingAny, setLoadingAny] = React.useState(false);
//   const [finishedUploading, setFinishedUploading] = React.useState(false);
//   const [uploadState, setUploadState] = React.useState<"idle" | "uploading" | "complete" | "error">(
//     "idle"
//   );
//   const [progress, setProgress] = React.useState(0);

//   React.useEffect(() => {
//     if (initialFile) {
//       // Instead of using uploader.add, we'll rely on Uploady's auto-upload feature
//       // The file will be automatically processed when the component mounts
//       setUploadState("uploading");
//     }
//   }, [initialFile]);

//   return (
//     <React.Fragment>
//       {!textOnly && onDismiss && (
//         <button
//           className="btn btn-sm btn-circle absolute right-2 top-2"
//           disabled={loadingAny}
//           onClick={onDismiss}
//         >
//           x
//         </button>
//       )}

//       <Uploady
//         fileFilter={(file) => typeof file !== "string" && "size" in file && file.size <= 10e6}
//         autoUpload={!showPreviewConfirmation}
//         clearPendingOnAdd
//         multiple={!disallowMultiple && !showPreviewConfirmation}
//         accept=".png,.jpg,.jpeg,.heic,.heif"
//       >
//         <ImageUploadButton
//           btnClassName={btnClassName}
//           overridePopup={overridePopup}
//           btnContent={btnContent}
//           iconClassName={iconClassName}
//           iconOverride={iconOverride}
//           loading={loadingAny}
//           processing={finishedUploading}
//           mediaOptions={mediaOptions}
//           onStart={() => {
//             console.log('onStart')
//             setLoadingAny(true);
//             setUploadState("uploading");
//             onStart?.();
//           }}
//           onError={() => {
//             console.log('onError')
//             setUploadState("error");
//             onError();
//           }}
//           setUploadId={(id) => {
//             console.log('setUploadId')
//             setUploadId(id);
//             onUploadIdReceived?.(id);
//           }}
//           onFinalize={() => {
//             console.log('onFinalize')
//             setFinishedUploading(true);
//             setLoadingAny(false);
//             console.log(uploadId, 'dsdsdsds------------')
//             if (uploadId) {
//               setUploadState("complete");
//               onComplete(uploadId);
//             }
//           }}
//           onProgress={setProgress}
//           showPreviewConfirmation={showPreviewConfirmation}
//           textOnly={textOnly}
//           uploadState={uploadState}
//         />
//       </Uploady>
//     </React.Fragment>
//   );
// }

// export default ImageUploader;

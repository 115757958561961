import React from "react";

const GOOGLE_ID = "G-XEJ73MX2ZQ";

/* cSpell:disable */
export function GoogleComponent({ tag_id = GOOGLE_ID }: { tag_id?: string }): React.JSX.Element {
  React.useEffect(() => {
    // Create the script element
    const scriptSrc = document.createElement("script");
    scriptSrc.src = `https://www.googletagmanager.com/gtag/js?id=${tag_id}`;
    scriptSrc.async = true;
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${tag_id}');
    `;

    // Append the script to the document head
    document.head.appendChild(scriptSrc);
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(scriptSrc);
      document.head.removeChild(script);
    };
  }, []);

  return <div></div>;
}

const FB_ID = "803178251631575";
export function FacebookPixelComponent({
  pixel_id = FB_ID,
}: {
  pixel_id?: string;
}): React.JSX.Element {
  React.useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pixel_id}');
      fbq('track', 'PageView');
    `;

    // Append the script to the document head
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Your component's content */}
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${pixel_id}&ev=PageView&noscript=1`}
        />
      </noscript>
    </div>
  );
}

// export functions EventYMHPixel: React.JSX.Element {

//   return
// <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16648321787">
// </script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'AW-16648321787');
// </script>
// )
// }

const ymhPixelId = "AW-16648321787";

/* cSpell:disable */
export function YMHPixel(): React.JSX.Element {
  React.useEffect(() => {
    // Create the script element
    const scriptSrc = document.createElement("script");
    scriptSrc.src = `https://www.googletagmanager.com/gtag/js?id=${ymhPixelId}`;
    scriptSrc.async = true;
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${ymhPixelId}');
    `;

    // Append the script to the document head
    document.head.appendChild(scriptSrc);
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(scriptSrc);
      document.head.removeChild(script);
    };
  }, []);

  return <div></div>;
}

// VirtualizerContext.tsx
import React, { useState } from "react";
import { Virtualizer } from "@tanstack/react-virtual";

type VirtualizerContextType = {
  virtualizer: Virtualizer<any, Element> | null;
  setVirtualizer: (virtualizer: Virtualizer<any, Element> | null) => void;
};

export const VirtualizerContext = React.createContext<VirtualizerContextType>({
  virtualizer: null,
  setVirtualizer: () => {},
});

export const VirtualizerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [virtualizer, setVirtualizer] = useState<Virtualizer<any, Element> | null>(null);
  return (
    <VirtualizerContext.Provider value={{ virtualizer, setVirtualizer }}>
      {children}
    </VirtualizerContext.Provider>
  );
};

import { SelectPills, SelectPillsProps } from "../inputs/select-pills";
import {
  SelectWithDetails,
  SelectWithDetailsOption,
} from "shared-web-react/dist/widgets/select-details";
import { classNames, filterNulls } from "shared/dist/util";
import {
  useGenderListQuery,
  useUpdateUserGenderMutation,
  useUserGenderQuery,
} from "shared/dist/__generated__/components";

import { GenderPicker } from "shared-web-react/dist/widgets/gender-picker";
import React from "react";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useMyId } from "shared/dist/auth-data";
import { useRefetchMyBioSummary } from "../../screens/user-page/common";
import { ProfileSettingBubbleChecked } from "../../screens/user-page/settings/dating/dating-main";

export type GenderPillsProps = { showCouples?: boolean } & Omit<SelectPillsProps, "choices">;
export function GenderPills({
  showCouples,
  defaultValue,
  ...props
}: GenderPillsProps): React.JSX.Element {
  const [showAll, setShowAll] = React.useState(false);
  const listQuery = useGenderListQuery();
  const allGenders = listQuery?.data?.genders ?? [];
  const genders = React.useMemo(
    () =>
      allGenders
        .filter((g) => true || g.seq_no > 800 || defaultValue?.includes(g.id))
        .map((gender) => ({
          value: gender.id,
          label: gender.name.en_US,
        })),
    [listQuery, showAll, defaultValue]
  );

  const couplesOption = React.useMemo(() => {
    return showCouples ? [{ value: "couples", label: "Couples" }] : [];
  }, [showCouples]);

  const genderDescs = React.useMemo(() => [...genders, ...couplesOption], [genders, couplesOption]);

  return <SelectPills defaultValue={defaultValue} {...props} choices={genderDescs} />;
}

type GenderPickerForUsersProps = {
  defaultKey?: string;
  inputClassName?: string;
  buttonClassName?: string;
  suppressToast?: boolean;
};

export function GenderPickerForUsers(props: GenderPickerForUsersProps): React.JSX.Element {
  const myId = useMyId();
  const refetchBio = useRefetchMyBioSummary();
  return <GenderPicker {...props} forUserId={myId} onSave={refetchBio} />;
  // const my_id = useMyId()!;
  // const [saveMutation, saveMutationResult] = useUpdateUserGenderMutation();
  // const listQuery = useGenderListQuery();
  // const { data } = useUserGenderQuery({
  //   variables: { user_id: my_id },
  // });
  // const [selected, setSelected] = React.useState<null | SelectWithDetailsOption>(null);
  // const save = React.useCallback(
  //   async (gender_id: string | null) => {
  //     await saveMutation({ variables: { gender_id, id: my_id } });
  //     refetchBio();
  //   },
  //   [selected, saveMutation]
  // );
  // const genders = React.useMemo(
  //   () =>
  //     listQuery.data?.genders?.map(
  //       (gender) =>
  //         [gender.id, gender.name.en_US, gender.description.en_US] as SelectWithDetailsOption
  //     ),
  //   [listQuery]
  // );
  // const addToast = useAddToast();
  // return (
  //   <div className="space-y-2">
  //     <div>
  //       <label className="label">Gender</label>
  //       <SelectWithDetails
  //         options={genders ?? []}
  //         loading={saveMutationResult.loading}
  //         disabled={!(genders && genders.length > 0)}
  //         inputClassName={inputClassName}
  //         buttonClassName={buttonClassName}
  //         defaultKey={data?.users_by_pk?.gender_id ?? defaultKey}
  //         disableOnLoading
  //         placeholder={"Gender"}
  //         selected={selected}
  //         setSelected={(selectedOption) => {
  //           save(selectedOption?.[0] ? String(selectedOption[0]) : null).then(
  //             () => suppressToast || addToast({ content: "Saved!" })
  //           );
  //           setSelected(selectedOption);
  //         }}
  //       />
  //     </div>
  //   </div>
  // );
}

import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AccountType,
  ObAccountType,
  ObBirthday,
  ObGender,
  ObOrientation,
  ObPartnerGender,
  ObPartnerName,
} from "./bday-gender";
import { allRoutes, useMustBeLoggedIn } from "../../util/routes";

import { BigModal } from "../../widgets/bio/big-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H1 } from "shared-web-react/dist/widgets/text";
import { ModalContainer } from "../../widgets/container";
import { ObBasics } from "./basics";
import { ObEmail } from "./ob-email";
import { ObExplanation } from "./explanation";
import { ObHopeToFind } from "./hope-to-find";
import { ObLocation } from "./location";
import { ObMedia } from "./media";
import { ObPendingApproval } from "./pending-approval-screen";
import { ObSplashScreen } from "./onboarding-splash-screens";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import clsx from "clsx";
import { faArrowLeftFromArc } from "@fortawesome/pro-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import {
  Onboarding_Statuses_Enum,
  useCompleteOnboardingMutation,
  useJoinPublicEvent553Mutation,
} from "shared/dist/__generated__/components";
import { useMyInfo, useMySlug } from "../bio/bio-settings";
import { Button } from "../../components/atoms/Button";
import { ObTagline } from "./widgets";
import mixpanel from "mixpanel-browser";
import { OnboardingContext, OnboardingProvider } from "./onboarding-context";

function Progress({ val, max }: { val: number; max: number }) {
  return (
    <progress
      className={clsx(
        "absolute z-50 rounded-none bg-primary-content progress progress-dark-sunset w-full",
        "[&::-webkit-progress-bar]:rounded-none [&::-webkit-progress-value]:rounded-none "
      )}
      max={max}
      value={val}
    />
  );
}

export const OB_LAST_PAGE_IDX = 13;

// Wrap both components with OnboardingProvider
export function BioOnboardingWithProvider(): React.JSX.Element {
  return (
    <OnboardingProvider>
      <BioOnboarding />
    </OnboardingProvider>
  );
}

export function EventPreOnboardingWithProvider(): React.JSX.Element {
  return (
    <OnboardingProvider>
      <EventPreOnboarding />
    </OnboardingProvider>
  );
}

// Original components renamed to internal names
function BioOnboarding(): React.JSX.Element {
  const location = useLocation();
  const startingIdx: number | null = location.state?.startingIdx;
  const [showWelcomeModal, setShowWelcomeModal] = React.useState(false);
  const [screenIdx, setScreenIdx] = React.useState(startingIdx || 0);
  const [completeMutation] = useCompleteOnboardingMutation();
  const addToast = useAddToast();
  const { accountType } = React.useContext(OnboardingContext);
  useMustBeLoggedIn();

  // Debug log for account type
  React.useEffect(() => {
    console.log("Current account type:", accountType);
  }, [accountType]);

  React.useEffect(() => {
    const pageMatch = window.location.search?.match(/.*page=(\d).*/);
    if (pageMatch) {
      const page = parseInt(pageMatch[1]);
      if (page >= 0 && page <= OB_LAST_PAGE_IDX) setScreenIdx(page);
    }
  }, []);

  const navigate = useNavigate();
  const slugInfo = useMySlug();
  const onNext = React.useCallback(() => setScreenIdx(screenIdx + 1), [screenIdx, setScreenIdx]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [disableButton, setDisableButton] = React.useState<boolean>(false);

  const onComplete = React.useCallback(async () => {
    const result = await completeMutation();
    if (result.data?.lm_complete_onboarding?.__typename === "L_Simple_Response_Error") {
      addToast({
        content: "An error occurred, please try again",
      });
    } else {
      navigate(allRoutes.me.buildPath({}));
    }
  }, [navigate, completeMutation]);

  const backButton = () => {
    if (screenIdx - 1 >= 0) setScreenIdx(screenIdx - 1);
  };
  React.useEffect(() => {
    return; // disable automatic redirects for now
  }, [slugInfo, navigate]);

  const screenToDisplay = match(screenIdx)
    .with(0, () => <ObBasics {...{ setLoading, setDisableButton }} />)
    .with(1, () => <ObEmail {...{ onNext: onNext, setLoading, setDisableButton }} />)
    .with(2, () => <ObBirthday {...{ onNext, setLoading, setDisableButton }} />)
    .with(3, () => <ObGender {...{ onNext, setLoading, setDisableButton }} />)
    .with(4, () => <ObOrientation {...{ setDisableButton }} />)
    .with(5, () => <ObAccountType {...{ setDisableButton, onNext }} />)
    .with(6, () =>
      accountType === "Joint" ? (
        <ObPartnerName {...{ setDisableButton, onNext }} />
      ) : (
        <ObMedia {...{ setDisableButton, onNext }} />
      )
    )
    .with(7, () =>
      accountType === "Joint" ? (
        <ObPartnerGender {...{ setDisableButton, onNext }} />
      ) : (
        <ObLocation {...{ onNext: onNext, setDisableButton }} />
      )
    )
    .with(8, () =>
      accountType === "Joint" ? (
        <ObMedia {...{ setDisableButton, onNext }} />
      ) : (
        <ObTagline {...{ onNext: onNext, setDisableButton }} />
      )
    )
    .with(9, () =>
      accountType === "Joint" ? (
        <ObLocation {...{ onNext: onNext, setDisableButton }} />
      ) : (
        <ObSplashScreen {...{ onNext: onNext, screenType: "welcome", navIndexNumber: 1 }} />
      )
    )
    .with(10, () =>
      accountType === "Joint" ? (
        <ObTagline {...{ onNext: onNext, setDisableButton }} />
      ) : (
        <ObSplashScreen {...{ onNext: onNext, screenType: "match-network", navIndexNumber: 2 }} />
      )
    )
    .with(11, () =>
      accountType === "Joint" ? (
        <ObSplashScreen {...{ onNext: onNext, screenType: "welcome", navIndexNumber: 1 }} />
      ) : (
        <ObSplashScreen {...{ onNext: onComplete, screenType: "events", navIndexNumber: 3 }} />
      )
    )
    .with(12, () =>
      accountType === "Joint" ? (
        <ObSplashScreen {...{ onNext: onComplete, screenType: "events", navIndexNumber: 3 }} />
      ) : (
        <ObExplanation {...{ onNext }} />
      )
    )
    .with(13, () =>
      accountType === "Joint" ? (
        <ObExplanation {...{ onNext }} />
      ) : (
        <ObExplanation {...{ onNext }} />
      )
    )
    .otherwise(() => <ObExplanation {...{ onNext }} />);

  return (
    <ModalContainer
      size="lg"
      className={clsx(
        "!bg-[#2A2C38] relative top-0 left-0 right-0 flex-1 flex flex-col justify-stretch items-stretch",
        "max-h-full overflow-hidden px-6 max-lg:h-full"
      )}
    >
      <div className="flex-0">
        {/* <Progress val={screenIdx + 1} max={OB_LAST_PAGE_IDX + 1} /> */}
        <div className="text-3xl relative top-9 z-10" onClick={backButton}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ cursor: "pointer" }} color="white" />
        </div>
      </div>
      <div
        className={clsx(
          "flex-1 flex-col flex min-h-0 mt-10 max-h-full pt-safe overflow-auto justify-center"
        )}
      >
        {screenToDisplay}
      </div>
      <div className="flex-0 mb-20">
        <Button
          variant="primary"
          onClick={() => {
            screenIdx === 0 ? setShowWelcomeModal(true) : onNext();
          }}
          className={
            loading || disableButton
              ? "opacity-50 cursor-not-allowed"
              : "bg-gradient-to-r from-red-500 to-orange-400 hover:from-red-600 hover:to-orange-500 text-white"
          }
          disabled={loading || disableButton}
        >
          Next
        </Button>
      </div>
      {showWelcomeModal && (
        <BigModal
          onDismiss={() => {
            setShowWelcomeModal(false);
            onNext();
          }}
        >
          <div className="flex flex-col items-center justify-center p-8 bg-[#2A2C38] rounded-3xl shadow-2xl max-w-sm mx-auto">
            {/* Emoji */}
            <H1 className="text-6xl mb-4 animate-bounce drop-shadow-md">😈</H1>

            {/* Title */}
            <span className="text-3xl font-bold text-white leading-tight tracking-wide">
              Welcome to Simmr!
            </span>

            {/* Subtitle */}
            <p className="text-center text-gray-300 my-4 text-sm leading-relaxed">
              Exciting encounters await you.
              <br />
              But first, let’s create your profile.
            </p>

            {/* Button */}
            <Button
              onClick={() => {
                setShowWelcomeModal(false);
                onNext();
              }}
              variant="primary"
            >
              Let's Go
            </Button>
          </div>
        </BigModal>
      )}
    </ModalContainer>
  );
}

function EventPreOnboarding(): React.JSX.Element {
  const location = useLocation();
  const startingIdx: number | null = location.state?.startingIdx;
  const [screenIdx, setScreenIdx] = React.useState(startingIdx || 0);
  const [joinEventMutation] = useJoinPublicEvent553Mutation();
  const addToast = useAddToast();
  const { accountType } = React.useContext(OnboardingContext);
  const { id } = useMyInfo();
  // Debug log for account type
  React.useEffect(() => {
    console.log("Event onboarding - Current account type:", accountType);
  }, [accountType]);

  useMustBeLoggedIn();
  React.useEffect(() => {
    const pageMatch = window.location.search?.match(/.*page=(\d).*/);
    if (pageMatch) {
      const page = parseInt(pageMatch[1]);
      if (page >= 0 && page <= OB_LAST_PAGE_IDX) setScreenIdx(page);
    }
  }, []);

  const navigate = useNavigate();
  const onNext = React.useCallback(() => setScreenIdx(screenIdx + 1), [screenIdx, setScreenIdx]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [disableButton, setDisableButton] = React.useState<boolean>(false);

  const onComplete = React.useCallback(async () => {
    const eventId = new URLSearchParams(window.location.search).get("event_id");
    if (!eventId) {
      addToast({
        content: "No event ID found",
      });
      return;
    }
    const result = await joinEventMutation({
      variables: {
        user_id: id ?? "",
        event_id: eventId,
      },
    });
    if (result.data?.insert_event_attendees_one?.event_id !== eventId) {
      // if (result.data?.lm_join_public_event?.__typename === "L_Simple_Response_Error") {
      addToast({
        content: "An error occurred, please try again",
      });
    } else {
      navigate(allRoutes.EVENTS.buildPath({ event_id: eventId }));
    }
  }, [navigate, joinEventMutation]);

  const backButton = () => {
    if (screenIdx - 1 >= 0) setScreenIdx(screenIdx - 1);
  };

  const screenToDisplay = match(screenIdx)
    .with(0, () => <ObBasics {...{ setLoading, setDisableButton, isEventOnboarding: true }} />)
    .with(1, () => <ObEmail {...{ onNext: onNext, setLoading, setDisableButton }} />)
    .with(2, () => <ObBirthday {...{ onNext, setLoading, setDisableButton }} />)
    .with(3, () => <ObGender {...{ onNext, setLoading, setDisableButton }} />)
    .with(4, () => <ObOrientation {...{ setDisableButton }} />)
    .with(5, () => <ObAccountType {...{ setDisableButton, onNext }} />)
    .with(6, () =>
      accountType === "Joint" ? (
        <ObPartnerName {...{ setDisableButton, onNext }} />
      ) : (
        <ObMedia {...{ setDisableButton, onNext }} />
      )
    )
    .with(7, () =>
      accountType === "Joint" ? (
        <ObPartnerGender {...{ setDisableButton, onNext }} />
      ) : (
        <ObLocation {...{ onNext: onNext, setDisableButton }} />
      )
    )
    .with(8, () =>
      accountType === "Joint" ? (
        <ObMedia {...{ setDisableButton, onNext }} />
      ) : (
        <ObPendingApproval />
      )
    )
    .with(9, () =>
      accountType === "Joint" ? (
        <ObLocation {...{ onNext: onNext, setDisableButton }} />
      ) : (
        <ObPendingApproval />
      )
    )
    .with(10, () =>
      accountType === "Joint" ? (
        <ObTagline {...{ onNext: onNext, setDisableButton }} />
      ) : (
        <ObPendingApproval />
      )
    )
    .with(11, () => (accountType === "Joint" ? <ObPendingApproval /> : <ObPendingApproval />))
    .otherwise(() => <ObExplanation {...{ onNext }} />);

  return (
    <ModalContainer
      size="lg"
      className={clsx(
        "!bg-[#2A2C38] relative top-0 left-0 right-0 flex-1 flex flex-col justify-stretch items-stretch",
        "max-h-full overflow-hidden px-6 max-lg:h-full"
      )}
    >
      <div className="flex-0">
        {/* <Progress val={screenIdx + 1} max={OB_LAST_PAGE_IDX + 1} /> */}
        <div className="text-3xl relative top-9 z-10" onClick={backButton}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ cursor: "pointer" }} color="white" />
        </div>
      </div>
      <div
        className={clsx(
          "flex-1 flex-col flex min-h-0 mt-10 max-h-full pt-safe overflow-auto justify-center"
        )}
      >
        {screenToDisplay}
      </div>
      <div className="flex-0 mb-20">
        <Button
          variant="primary"
          onClick={onNext}
          className={
            loading || disableButton
              ? "opacity-50 cursor-not-allowed"
              : "bg-gradient-to-r from-red-500 to-orange-400 hover:from-red-600 hover:to-orange-500 text-white"
          }
          disabled={loading || disableButton}
        >
          Continue
        </Button>
      </div>
    </ModalContainer>
  );
}

// Export wrapped components
export {
  BioOnboardingWithProvider as BioOnboarding,
  EventPreOnboardingWithProvider as EventPreOnboarding,
};

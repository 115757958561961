// import "./util/push-notifications";
import "./capacitor-plugins/logging";

import { AuthDataImplementation, useNativeCookieSetter } from "./util/native-auth-data-manager";
import { BioOnboarding, EventPreOnboarding, OB_LAST_PAGE_IDX } from "./screens/onboarding/main";
import { App as CapApp, URLOpenListenerEvent } from "@capacitor/app";
import { EnvProvider, useHasuraEndpoint, useMkUrl } from "shared/dist/util/env";
import { EventDetails, EventDetailsFromSlug } from "./screens/other-pages/event-detail";
import {
  EventsHome,
  EventsMyPast,
  EventsMyUpcoming,
  EventsMyJoined,
  EventsPublic,
} from "./screens/other-pages/events/events-home";
import { MakeApolloClient, NetworkingError } from "shared/dist/apollo";
import { MessagingHome, MessagingLanding } from "./screens/messaging/home";
import { Onboarding_Statuses_Enum, useGetMyEmailQuery } from "shared/dist/__generated__/components";
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { P, match } from "ts-pattern";
import {
  PersonaDialogProvider,
  PersonaIdentityResponseComponent,
} from "./screens/user-page/settings/identity/persona";
import { RedirectTo, RequireAnonymous, RequireLogin } from "./util/redirects";
import { Provider as RollbarProvider, useRollbar } from "@rollbar/react";
import { SettingsIndexPage, UserSettings } from "./screens/user-page/settings/settings-main";
import {
  SignUpTriggerLarge,
  SignUpTriggerStateProvider,
} from "./screens/user-page/signup-triggers";
import { useLogout, useMyId } from "shared/dist/auth-data";
import { useMyInfo, useMySlug } from "./screens/bio/bio-settings";

import { AUTH_REDIRECT_COOKIE_KEY } from "shared-web-react/dist/constants";
import { AblyListener } from "./util/ably-listener";
import { AcceptPartnerInvite } from "./screens/user-page/settings/accept-partner-invite";
import { AccountCenter } from "./screens/user-page/settings/account-center";
import { AssetsProvider } from "shared-web-react/dist/util/assets-provider";
import { Capacitor } from "@capacitor/core";
import { ColorTestWidget } from "./widgets/color-test";
import { ConfirmProvider } from "shared-web-react/dist/widgets/confirm-provider";
import Cookies from "js-cookie";
import { DatingSettings } from "./screens/user-page/settings/dating/dating-main";
import { DevInfo } from "./widgets/dev-info";
import { DiscoveryConnections } from "./screens/user-page/discovery/connections";
import { DiscoveryMain } from "./screens/user-page/discovery/profile-grid";
import { DiscoveryMyIncomingLikes } from "./screens/user-page/discovery/my-likes";
import { DiscoveryRoot } from "./screens/user-page/discovery/discovery";
import { EditEvent } from "./screens/other-pages/events/edit-event";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { EventSplash } from "./screens/other-pages/event-splash";
import { FeatureFlagsProvider } from "shared/dist/util/feature-flags";
import { FriendsList } from "./screens/user-page/friends/friends-list";
import { FriendsMain } from "./screens/user-page/friends/friends-main";
import { Home } from "./screens/user-page/home";
import { ImageCacheProvider } from "shared-web-react/dist/widgets/lazy-image";
import { InboundFriendsRequestList } from "./screens/user-page/friends/friends-inbound-request-list";
import { IndicatorProvider } from "shared/dist/indicators-context";
import { Invites } from "./screens/user-page/invites";
import { Keyboard } from "@capacitor/keyboard";
import { LandingMain } from "./screens/landingv2/main";
import { Logout } from "shared-web-react/dist/screens/logout";
import { Me } from "./screens/me";
import { MyVouches } from "./screens/user-page/discovery/my-vouches";
import { NeonSpinner } from "./widgets/neon-spinner";
import Notifications from "./screens/user-page/notifications";
import { ObInvitationCodeEntry } from "./screens/onboarding/invitation-code-entry";
import { OnScreenLoggerProvider } from "shared-web-react/dist/util/on-screen-logger";
import { OutboundFriendsRequestList } from "./screens/user-page/friends/friends-outbound-request-list";
import { PageTitleProvider } from "shared-web-react/dist/widgets/nav-stack-view";
import { PermissionRequestCheckProvider } from "./permissions/permission-request-dialog";
import { PermissionsStateProvider } from "./permissions/permissions-context";
import { PersonalSettings } from "./screens/user-page/settings/personal";
import { Privacy } from "./screens/privacy/main";
import { PrivacySettings } from "./screens/user-page/settings/privacy";
import { ProfilePage } from "./screens/user-page/profile/profile-page";
import { ProfileSettings } from "./screens/user-page/settings/profile";
import { QueryCacheHydrator } from "./util/query-cache-hydrator";
import React from "react";
import { ReactionsProvider } from "./widgets/reactions";
import { ReactionsView } from "./widgets/reactions-view";
import { ReportMedia } from "./screens/user-page/report-media/report-media";
import { RequestList } from "./screens/messaging/request-list";
import Rollbar from "rollbar";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { Support } from "./screens/support/main";
import { Terms } from "./screens/terms/main";
import { TestBioActions } from "./screens/user-page/bio-basics";
import { ThreadList } from "./screens/messaging/thread-list";
import { ThreadView } from "./screens/messaging/thread-view";
import { ToastProvider } from "shared-web-react/dist/widgets/toast-provider";
import { UiDev } from "./widgets/dev-helpers";
import { UiTestPages } from "./screens/dev-test-pages/list";
import { UserAbout } from "./screens/user-page/about";
import { UserEvents } from "./screens/user-page/user-events";
import { UserFeed } from "./screens/user-page/profile/user-feed";
import { UserGallery } from "./screens/user-page/gallery";
import { UserPageNavWrapper } from "./screens/user-page/main-nav";
import { UserSmsAuth } from "./sms-auth-web";
import { UserTravel } from "./screens/user-page/travel";
import { PublicEventsDetail, PublicEventsRoot } from "./screens/other-pages/events/public";
import { VersionCheck } from "./util/version-check";
import { allRoutes } from "./util/routes";
import logoCirclePadded1024 from "../../frontend-web-bio-page/src/assets/icon-logos/c-interlock-1024.png";
import logoCircleUnpadded from "../../frontend-web-bio-page/src/assets/icon-logos/c-interlock.png";
import logoNeonWhite from "../../frontend-web-bio-page/src/assets/neon-logos/candid-neon-white.png";
import mixpanel from "mixpanel-browser";
import { stringify } from "shared/dist/util";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useIndicators } from "./widgets/indicators";
import { useMixpanelIdentify } from "shared/dist/mixpanel-client";
import { Container } from "./widgets/container";
import clsx from "clsx";
import { BlockedUsers, DiscoveryHiddenUsers } from "./screens/user-page/settings/safety";
import { BlockedUsersPage } from "./screens/user-page/settings/blocked-users";
import { DiscoveryUsersPage } from "./screens/user-page/settings/discovery-users";
import { DatingSettingsPage } from "./screens/user-page/settings/dating-settings";
import EventAttendees from "./screens/other-pages/events/event-attendees";
import PublicEventsDetailT from "./screens/other-pages/events/public/detail-quick-ob";
import { VirtualizerProvider } from "./util/virtualizer-context";
const simmrLogoRed = "../../frontend-web-bio-page/src/assets/simmer-logo-red.png";

export function RedirectToLanding(): React.JSX.Element {
  const navigate = useNavigate();
  React.useEffect(() => {
    Cookies.set(AUTH_REDIRECT_COOKIE_KEY, window.location.pathname);
    console.log(
      "🚀 ~ RedirectToLanding App.tsx:90 ~ React.useEffect ~ window.location.pathname:",
      window.location.pathname
    );
    setTimeout(() => {
      navigate("/");
    }, 100);
  }, []);
  return <SpinnerCentered />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <RouterRoot />,
    errorElement: <ErrorComp caption={"Were sorry but an unknown error occurred."} />,
    children: [
      {
        path: allRoutes.logout.path,
        element: <Logout />,
      },
      {
        index: true,
        element: <LandingMain />,
        errorElement: <ErrorComp caption={"Anonymous Router Error"} />,
      },
      {
        path: allRoutes.PERSONA_VERIFICATION_RESPONSE.path,
        element: (
          <RequireLogin>
            <PersonaIdentityResponseComponent />
            <RedirectTo setAuthRedirect to={allRoutes.login.path} />
          </RequireLogin>
        ),
      },
      {
        path: allRoutes.RELATIONSHIP_INVITE.path,
        element: (
          <RequireLogin>
            <AcceptPartnerInvite />
            <RedirectTo setAuthRedirect to={allRoutes.login.path} />
          </RequireLogin>
        ),
      },
      {
        path: allRoutes.login.path,
        element: (
          <RequireAnonymous>
            <UserSmsAuth />
          </RequireAnonymous>
        ),
      },
      {
        path: allRoutes.PROMO.path,
        element: (
          <RequireAnonymous>
            <UserSmsAuth />
          </RequireAnonymous>
        ),
      },
      {
        path: allRoutes.me.path,
        errorElement: (
          <ErrorComp caption={"Were sorry but an error occurred loading your profile."} />
        ),
        element: (
          // <RequireLogin>
          <Me />
          // </RequireLogin>
        ),
      },
      {
        path: allRoutes.logout.path,
        element: <RequireLogin><Logout /></RequireLogin>, //prettier-ignore
      },
      {
        path: allRoutes.ONBOARDING.path,
        element: <RequireLogin><BioOnboarding /></RequireLogin>, //prettier-ignore
      },
      {
        path: allRoutes.INVITATION_CODE_ENTRY.path,
        element: <RequireLogin><ObInvitationCodeEntry /></RequireLogin>, //prettier-ignore
      },
      {
        path: allRoutes.HOME.path,
        errorElement: (
          <ErrorComp caption={"Were sorry but an error occurred loading your home screen."} />
        ),
        element: (
          <RequireLogin wrapAll>
            <Home />
            <SignUpTriggerLarge />
          </RequireLogin>
        ),
      },
      {
        path: allRoutes.DISCOVERY.path,
        errorElement: (
          <ErrorComp caption={"Were sorry but an error occurred loading your matches."} />
        ),
        element: (
          <RequireLogin wrapAll noWrap>
            <DiscoveryRoot />
            <SignUpTriggerLarge />
          </RequireLogin>
        ),
        children: [
          { index: true, element: <DiscoveryMain /> },
          {
            path: allRoutes.DISCOVERY.$.TEST_EMPTY.relativePath,
            element: <DiscoveryMain override="empty" />,
          },
          // {
          //   path: allRoutes.DISCOVERY.$.TEST_WAITLIST.relativePath,
          //   element: <DiscoveryMain override="waitlist" />,
          // },
          {
            path: allRoutes.DISCOVERY.$.TEST_DUMMY_DATA.relativePath,
            element: <DiscoveryMain override="dummy_data" />,
          },
          {
            path: allRoutes.DISCOVERY.$.TEST_ERROR.relativePath,
            element: <DiscoveryMain override="error" />,
          },
          {
            path: allRoutes.DISCOVERY.$.TEST_NOT_READY.relativePath,
            element: <DiscoveryMain override="not_ready" />,
          },
          {
            path: allRoutes.DISCOVERY.$.MY_INCOMING_LIKES.relativePath,
            element: <DiscoveryMyIncomingLikes />,
          },
          {
            path: allRoutes.DISCOVERY.$.MATCHES.relativePath,
            element: <DiscoveryConnections />,
          },
        ],
      },
      {
        path: allRoutes.PROFILE.FRIENDS.relativePath,
        element: (
          <UserPageNavWrapper>
            <FriendsMain />
          </UserPageNavWrapper>
        ),
        children: [
          { index: true, element: <FriendsList friendType="active" /> },
          {
            path: allRoutes.PROFILE.$.FRIENDS.$.OUTBOUND_REQUESTS.relativePath,
            element: <OutboundFriendsRequestList />,
          },
          {
            path: allRoutes.PROFILE.$.FRIENDS.$.INBOUND_REQUESTS.relativePath,
            element: <InboundFriendsRequestList />,
          },
          {
            path: allRoutes.PROFILE.$.FRIENDS.$.MUTUAL.relativePath,
            element: <FriendsList friendType="mutual-friends" />,
          },
        ],
      },
      {
        path: allRoutes.PROFILE.VOUCHES.relativePath,
        element: (
          <UserPageNavWrapper>
            <MyVouches />
          </UserPageNavWrapper>
        ),
      },
      {
        path: allRoutes.PROFILE.path,
        errorElement: (
          <ErrorComp caption={"Were sorry but an error occurred loading this profile."} />
        ),

        element: (
          <UserPageNavWrapper noWrap>
            <ProfilePage />
          </UserPageNavWrapper>
        ),
        children: [
          { index: true, element: <UserFeed /> },
          {
            path: allRoutes.PROFILE.$.EVENTS.relativePath,
            element: (
              <RequireLogin wrapSnd>
                <UserEvents />
                <SignUpTriggerLarge />
              </RequireLogin>
            ),
          },
          {
            path: allRoutes.PROFILE.$.ABOUT.relativePath,
            element: <UserAbout />,
          },

          {
            path: allRoutes.PROFILE.$.TRAVEL.relativePath,
            element: (
              <RequireLogin wrapSnd>
                <UserTravel />
                <SignUpTriggerLarge />
              </RequireLogin>
            ),
          },
          {
            path: allRoutes.PROFILE.$.GALLERY.relativePath,
            element: (
              <RequireLogin wrapSnd>
                <UserGallery />
                <SignUpTriggerLarge />
              </RequireLogin>
            ),
          },
        ],
      },
      {
        path: allRoutes.PROFILE.MEDIA_ITEM.path,
        element: (
          <RequireLogin wrapAll>
            <ReactionsView />
          </RequireLogin>
        ),
      },
      {
        path: allRoutes.PROFILE.INVITES.path,
        element: (
          <RequireLogin wrapAll>
            <Invites />
          </RequireLogin>
        ),
      },
      {
        path: allRoutes.EVENTS.DETAILS.EDIT.path,
        element: <RequireLogin wrapAll><EditEvent /></RequireLogin>, // prettier-ignore
      },
      {
        path: allRoutes.EVENTS.path,
        element: <RedirectTo to={allRoutes.EVENTS.LIST.path} />, // prettier-ignore
      },
      {
        path: allRoutes.EVENTS.PUBLIC.path,
        element: <PublicEventsRoot />,
      },
      {
        path: allRoutes.EVENTS.PUBLIC.DETAILS.path,
        element: <PublicEventsDetail />,
      },
      {
        path: allRoutes.EVENTS.PUBLIC.DETAILS_A.path,
        element: <PublicEventsDetailT />,
      },
      {
        path: allRoutes.EVENTS.LIST.path,
        element: (<RequireLogin wrapAll><EventsHome /></RequireLogin>), // prettier-ignore
        children: [
          { index: true, element: <EventsPublic /> },
          { path: allRoutes.EVENTS.LIST.$.MY_JOINED.relativePath, element: <EventsMyJoined /> },
          { path: allRoutes.EVENTS.LIST.$.MY_PAST.relativePath, element: <EventsMyPast /> },
          { path: allRoutes.EVENTS.LIST.$.MY_UPCOMING.relativePath, element: <EventsMyUpcoming /> },
        ],
      },
      {
        path: allRoutes.PROFILE.NOTIFICATIONS.path,
        element: (
          <RequireLogin>
            <UserPageNavWrapper>
              <Notifications />
            </UserPageNavWrapper>
          </RequireLogin>
        ),
      },
      {
        path: allRoutes.EVENTS.CREATE.path,
        element: <RequireLogin wrapAll><EditEvent /></RequireLogin>, // prettier-ignore
      },
      {
        path: allRoutes.EVENT_FROM_SLUG.path,
        element: <UserPageNavWrapper><EventDetailsFromSlug /></UserPageNavWrapper>, // prettier-ignore
      },
      {
        path: allRoutes.EVENT_FROM_SLUG.SPLASH.path,
        element: <EventSplash />,
      },
      {
        path: allRoutes.EVENT_FROM_SLUG.ATTENDEES.path,
        element: (
          <RequireLogin wrapAll>
            <EventAttendees />
          </RequireLogin>
        ),
      },
      {
        path: allRoutes.EVENTS.DETAILS.path,
        element: <RequireLogin wrapAll><EventDetails /></RequireLogin>, // prettier-ignore
      },
      {
        path: "error-test",
        element: <ErrorComp caption="This is a dummy error" />,
      },
      {
        path: allRoutes.REQUESTS.path,
        element: (
          <RequireLogin wrapAll hideMenuLabelsInDesktop>
            <MessagingHome>
              <RequestList />
            </MessagingHome>
          </RequireLogin>
        ),
        children: [
          { index: true, element: <MessagingLanding /> },
          {
            path: allRoutes.REQUESTS.$.DETAIL.relativePath,
            element: <ThreadView />,
          },
        ],
      },
      {
        path: allRoutes.MESSAGING.LIST.path,
        element: (
          <RequireLogin wrapAll hideMenuLabelsInDesktop>
            <MessagingHome>
              <ThreadList />
            </MessagingHome>
          </RequireLogin>
        ),
        children: [
          { index: true, element: <MessagingLanding /> },
          {
            path: allRoutes.MESSAGING.LIST.$.THREAD.relativePath,
            element: <ThreadView />,
          },
        ],
      },
      {
        path: allRoutes.MESSAGING.path,
        element: <RedirectTo to={allRoutes.MESSAGING.LIST.buildPath({})} />,
      },
      {
        path: "ui-dev",
        element: <UserPageNavWrapper><UiDev /></UserPageNavWrapper>, // prettier-ignore
      },
      {
        path: allRoutes.SETTINGS.path,
        element: (
          <RequireLogin wrapAll>
            <UserSettings />
          </RequireLogin>
        ),
        children: [
          { index: true, element: <SettingsIndexPage /> },

          {
            path: allRoutes.SETTINGS.$.PROFILE.relativePath,
            element: <ProfileSettings onNext={() => {}} />,
          },
          {
            path: allRoutes.SETTINGS.$.PERSONAL.relativePath,
            element: <PersonalSettings />,
          },
          {
            path: allRoutes.SETTINGS.$.DATING.relativePath,
            element: <DatingSettingsPage />,
          },
          {
            path: allRoutes.SETTINGS.$.PRIVACY.relativePath,
            element: <PrivacySettings />,
          },

          // Old Settings routes TODO delete
          // {
          //   path: authenticatedRoutes.SETTINGS.$.NOTIFICATIONS.relativePath,
          //   element: <NotificationSettings />,
          // },
          // {
          //   path: allRoutes.SETTINGS.$.DETAILS.relativePath,
          //   element: <DetailsSettings />,
          // },
        ],
      },
      {
        path: allRoutes.ONBOARDING.PROMO.path,
        element: <RequireLogin><EventPreOnboarding /></RequireLogin>, //prettier-ignore
      },
      {
        path: allRoutes.SUPPORT.path,
        element: Capacitor.isNativePlatform() ? (
          <UserPageNavWrapper>
            <Support />
          </UserPageNavWrapper>
        ) : (
          <Support />
        ),
      },
      {
        path: allRoutes.SETTINGS.BLOCKED.path,
        element: (
          <RequireLogin wrapAll>
            <BlockedUsersPage />
          </RequireLogin>
        ),
      },
      {
        path: allRoutes.SETTINGS.HIDDEN.path,
        element: (
          <RequireLogin wrapAll>
            <DiscoveryUsersPage />
          </RequireLogin>
        ),
      },
      {
        path: allRoutes.DEV_UTILS.INFO.path,
        element: (
          <UserPageNavWrapper>
            <DevInfo />
          </UserPageNavWrapper>
        ),
      },
      {
        path: allRoutes.DEV_UTILS.COLORS.path,
        element: (
          <UserPageNavWrapper>
            <ColorTestWidget />
          </UserPageNavWrapper>
        ),
      },
      {
        path: allRoutes.DEV_UTILS.UI_TEST_PAGES.path,
        element: (
          <UserPageNavWrapper>
            <UiTestPages />
          </UserPageNavWrapper>
        ),
      },
      {
        path: allRoutes.DEV_UTILS.UI_TEST_PAGES.TEST_BIO_ACTIONS.path,
        element: (
          <UserPageNavWrapper>
            <TestBioActions />
          </UserPageNavWrapper>
        ),
      },
      {
        path: allRoutes.privacy.path,
        element: <Privacy />, //prettier-ignore
      },
      {
        path: allRoutes.terms.path,
        element: <Terms />, //prettier-ignore
      },
      {
        path: allRoutes.REPORT_MEDIA.path,
        element: (
          <RequireLogin wrapAll>
            <ReportMedia />
          </RequireLogin>
        ),
      },
    ],
  },
]);

function RollbarIdentityProvider({ children }: React.PropsWithChildren<{}>): React.JSX.Element {
  const rollbar = useRollbar();
  const id = useMyId();
  const { data } = useGetMyEmailQuery({ skip: !id, variables: { id: id! } });
  const slug = useMySlug()?.slug;
  // React.useEffect(() => {
  //   if (id) {
  //     emailQuery({ variables: { id } });
  //   }
  // }, [id]);
  React.useEffect(() => {
    rollbar.configure({
      payload: {
        person: {
          id: id,
          username: slug ?? undefined,
          email: data?.users_by_pk?.email ?? undefined,
        },
      },
    });
  }, [id, rollbar, data]);
  return <>{children}</>;
}

function RouterRoot(): React.JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug, eventId, loading, status, refetch } = useMyInfo();
  React.useEffect(() => {
    try {
      mixpanel.track(`Navigated to ${location.pathname.replaceAll("/", "_")}`);
    } catch (e) {
      console.error(`Error in mixpanel track:`, e);
    }
  }, [location]);
  React.useEffect(() => {
    if (loading) return;
    match(status)
      .with(Onboarding_Statuses_Enum.V2_01PendingEventUser, () => {
        navigate(allRoutes.ONBOARDING.PROMO.buildPath({}));
        !eventId && navigate(allRoutes.ONBOARDING.buildPath({}));
        return;
      })
      .with(Onboarding_Statuses_Enum.V2_01NewEventUser, () => {
        const route = Cookies.get(AUTH_REDIRECT_COOKIE_KEY);
        if (route) {
          Cookies.remove(AUTH_REDIRECT_COOKIE_KEY);
          navigate(route);
        }
        return;
      })
      .with(
        Onboarding_Statuses_Enum.V2_00New,
        Onboarding_Statuses_Enum.V2_05InvitedConfirmedObPending,
        () => {
          navigate(allRoutes.ONBOARDING.buildPath({}));
          return;
        }
      )
      .with(Onboarding_Statuses_Enum.V2_10ObCompletePendingApproval, () => {
        // navigate(allRoutes.ONBOARDING.buildPath({}), { state: { startingIdx: OB_LAST_PAGE_IDX } });
        const route = Cookies.get(AUTH_REDIRECT_COOKIE_KEY);
        if (route) {
          Cookies.remove(AUTH_REDIRECT_COOKIE_KEY);
          navigate(allRoutes.PROFILE.buildPath({ slug: slug ?? "" }));
        }
        return;
      })
      .with(Onboarding_Statuses_Enum.V2_20LiveUser, () => {
        const route = Cookies.get(AUTH_REDIRECT_COOKIE_KEY);
        if (eventId) return navigate(allRoutes.EVENTS.DETAILS.buildPath({ event_id: eventId }));
        if (route) {
          Cookies.remove(AUTH_REDIRECT_COOKIE_KEY);
          navigate(route);
        }
      })
      .with(Onboarding_Statuses_Enum.V2_25PausedUser, () => {
        const route = Cookies.get(AUTH_REDIRECT_COOKIE_KEY);
        if (route) {
          Cookies.remove(AUTH_REDIRECT_COOKIE_KEY);
          navigate(route);
        }
      })
      .with(P.nullish, () => loading || refetch())
      .exhaustive();
  }, [status, loading]);
  if (loading) return <></>;
  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  );
}

function UseIndicatorsWrapper({ children }: React.PropsWithChildren<{}>): React.JSX.Element {
  useIndicators();
  return <>{children}</>;
}

function MixpanelInit(): React.JSX.Element {
  useMixpanelIdentify();
  return <></>;
}

function UrlHandler(): React.JSX.Element {
  React.useEffect(() => {
    CapApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      try {
        const url = event.url;
        // handle url for staging
        const pathName = url.includes("staging")
          ? url.split("staging.com").pop()
          : url.split(".bio").pop();
        if (pathName) setTimeout(() => history.replaceState(null, "", pathName), 500);
      } catch (err) {
        console.error("🚀 ~ file: App.tsx:545 ~ CapApp.addListener ~ err:", err);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);
  return <></>;
}

function AppInner({}: {}): React.JSX.Element {
  // const id = useMyId();
  const logout = useLogout();
  const hasuraEndpoint = useHasuraEndpoint();
  // We need this to set the cookie on the domain for images
  useNativeCookieSetter();
  const addToast = useAddToast();
  const rollbar = useRollbar();
  const onApolloError = React.useCallback(
    (apolloError: NetworkingError) => {
      try {
        if (apolloError.errorId === "APOLLO_NETWORK_ERROR") {
          addToast({
            unitary: true,
            color: "error",
            content: "We're having trouble connecting to the internet. Please refresh the app.",
          });
          console.error("🚀 APOLLO_NETWORK_ERROR", apolloError);
        } else if (apolloError.errorId === "APOLLO_GQL_ERROR") {
          try {
            // This is hackish but errors were causing an infinite reload loop.
            // For this query, we can safely ignore errors.  Code in thread-messages.tsx
            const opContext = apolloError.operation?.getContext();
            console.log("🚀 ~ file: App.tsx:589 ~ AppInner ~ opContext:", opContext);
            if (opContext?.noToast) return;
            if (apolloError.operationName === "ThreadOgTags") return;
            const { errorDetails } = apolloError;
            const errorText = Array.isArray(errorDetails) ? (errorDetails[0]?.message ?? "") : "";

            console.error(
              "🚀 ~ file: App.tsx:592 ~ onError:",
              apolloError.errorId +
                ": " +
                (apolloError.operationName ?? "<no op name>") +
                errorText,
              stringify({
                returnedData: apolloError.returnedData,
                operationName: apolloError.operation,
                errorDetails: apolloError.errorDetails,
                query: JSON.stringify(apolloError.operation?.query ?? {}),
                variables: apolloError.operation?.variables,
                returnedErrors: apolloError.returnedErrors,
                path: window.location.pathname,
              })
            );
            rollbar.error(
              apolloError.errorId +
                ": " +
                (apolloError.operationName ?? apolloError.userFacingText),
              stringify({
                returnedData: apolloError.returnedData,
                operationName: apolloError.operation,
                errorDetails: apolloError.errorDetails,
                query: apolloError.operation?.query?.toString(),
                variables: apolloError.operation?.variables,
                returnedErrors: apolloError.returnedErrors,
                path: window.location.pathname,
              })
            );
          } catch (e: any) {
            rollbar.error("Could not log GQL Erorr:", e);
          }
          addToast({
            unitary: true,
            color: "error",
            content: "Please try again. If the problem persists, please contact support.",
          });
        }
      } catch (err) {
        console.error("🚀 - file: App.tsx:628 - AppInner - err:", err);
      }
    },
    [addToast]
  );
  return (
    <>
      <MakeApolloClient
        uri={hasuraEndpoint}
        resetTokenOnExpiration={() => {
          console.log("🚀 ~ file: App.tsx ~ line 96 ~ AppInner ~ logout");
          logout();
        }}
        showError={onApolloError}
      >
        <MixpanelInit />
        <ImageCacheProvider>
          <VersionCheck>
            <ConfirmProvider>
              <VirtualizerProvider>
                <RollbarIdentityProvider>
                  <SignUpTriggerStateProvider>
                    <UseIndicatorsWrapper>
                      <FeatureFlagsProvider>
                        <ReactionsProvider>
                          <PageTitleProvider>
                            <PermissionsStateProvider>
                              <PermissionRequestCheckProvider>
                                <PersonaDialogProvider>
                                  <RouterProvider router={router} />
                                  <QueryCacheHydrator />
                                  <UrlHandler />
                                  <AblyListener />
                                </PersonaDialogProvider>
                              </PermissionRequestCheckProvider>
                            </PermissionsStateProvider>
                          </PageTitleProvider>
                        </ReactionsProvider>
                      </FeatureFlagsProvider>
                    </UseIndicatorsWrapper>
                  </SignUpTriggerStateProvider>
                </RollbarIdentityProvider>
              </VirtualizerProvider>
            </ConfirmProvider>
          </VersionCheck>
        </ImageCacheProvider>
      </MakeApolloClient>
    </>
  );
}

const rollbarConfig: Rollbar.Configuration = {
  accessToken: import.meta.env.REACT_APP_ROLLBAR_ACCESS_TOKEN!,
  environment: import.meta.env.REACT_APP_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  logLevel: "info",
  reportLevel: "info",
  autoInstrument: true,
  enabled: import.meta.env.REACT_APP_ENV.match(/(.*prod.*)|(.*staging.*)/i),
};

export function App() {
  React.useEffect(() => {
    setTimeout(() => window.location.reload(), 1000 * 60 * 60 * 12);
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener("keyboardWillShow", () => {
        document.body.classList.add("keyboard-active");
        document.body.classList.remove("keyboard-inactive");
      });
      Keyboard.addListener("keyboardWillHide", () => {
        document.body.classList.add("keyboard-inactive");
        document.body.classList.remove("keyboard-active");
      });
      return () => {
        Keyboard.removeAllListeners();
      };
    }
    return () => {};
  }, []);
  return (
    <AssetsProvider
      assets={{
        logoCircleNoPadding: logoCircleUnpadded,
        logoCirclePadded: logoCirclePadded1024,
        logoWhiteFullWord: simmrLogoRed,
      }}
    >
      <EnvProvider
        env={{
          REACT_APP_ENV: import.meta.env.REACT_APP_ENV!,
          REACT_APP_HASURA_ENDPOINT: import.meta.env.REACT_APP_HASURA_ENDPOINT!,
          REACT_APP_MIXPANEL_PROJECT_TOKEN: import.meta.env.REACT_APP_MIXPANEL_PROJECT_TOKEN!,
          REACT_APP_PRODUCT_NAME: import.meta.env.REACT_APP_PRODUCT_NAME!,
          REACT_APP_BASE_URI: import.meta.env.REACT_APP_BASE_URI,
          REACT_APP_PERSONA_ENV_ID: import.meta.env.REACT_APP_PERSONA_ENV_ID!,
          REACT_APP_PERSONA_TEMPLATE_ID: import.meta.env.REACT_APP_PERSONA_TEMPLATE_ID!,
        }}
      >
        <ToastProvider>
          <RollbarProvider config={rollbarConfig}>
            <AuthDataImplementation>
              <IndicatorProvider>
                <OnScreenLoggerProvider>
                  <AppInner />
                </OnScreenLoggerProvider>
              </IndicatorProvider>
              <NeonSpinner />
            </AuthDataImplementation>
          </RollbarProvider>
        </ToastProvider>
      </EnvProvider>
    </AssetsProvider>
  );
}

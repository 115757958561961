import { Container } from "../../../widgets/container";
import { ContentBox } from "../../../widgets/content-box";
import { IdentitySettings } from "./identity";
import { Link, useNavigate } from "react-router-dom";
import {
  EmailNotificationSettings,
  NotificationSettings,
  PushNotificationSettings,
  SmsNotificationSettings,
} from "./notifications";
import { ProfilePrivacySettings } from "./profile-privacy-settings";
import { ShowCandidTeamInFeedSettings } from "./show-candid-team-in-feed";
import { allRoutes, useMustBeLoggedIn } from "../../../util/routes";
import { classNames } from "shared/dist/util";
import { useMyInfo } from "../../bio/bio-settings";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Text } from "../../../components/atoms/Text";
import { EditFieldProps, EditProfileSettingBubble, ProfileSettingBubble } from "./profile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faChevronDown, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useCallback, useMemo, useState } from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import {
  Face_Privacy_Levels_Enum,
  GqlOps,
  Profile_Privacy_Levels_Enum,
  ProfilePromptsQuery,
  useDeleteMyAccountMutation,
  useMyNotificationSettings453Query,
  usePauseUserMutation,
  useProfilePrivacySettings340Query,
  useProfilePromptsQuery,
  useUpdateMyPrivacySettingsMutation,
} from "shared/dist/__generated__/components";
import { useIdentityVerifiedStatus } from "./identity/common";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { clsx } from "clsx";
import Modal from "../../../components/molecules/Modal";
import { Button } from "../../../components/atoms/Button";
import { match } from "ts-pattern";
import mixpanel from "mixpanel-browser";

export function PrivacySettings(): React.JSX.Element {
  const my_id = useMustBeLoggedIn() ?? "";
  const { slug, loading: infoLoading, status, refetch } = useMyInfo();
  const { data, loading, error } = useMyNotificationSettings453Query({
    variables: { my_id },
    fetchPolicy: "cache-and-network",
  });

  const settings = data?.user_notification_settings_by_pk;
  const [hidden, setHidden] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState<{
    field: string | null | undefined;
    value: string | null | undefined;
    handleUpdate: (value: Profile_Privacy_Levels_Enum) => Promise<void>;
    options?: Array<string>;
    description?: string;
  }>({
    field: null,
    value: null,
    handleUpdate: async () => Promise.resolve(),
    options: [],
    description: "",
  });

  const {
    error: promptsError,
    loading: promptsLoading,
    data: promptsData,
  } = useProfilePromptsQuery({ variables: { my_id } });

  const navigate = useNavigate();

  return (
    <Container size="xs" className={classNames("flex flex-col h-full gap-2 pb-20 items-stretch")}>
      {!hidden ? (
        <>
          <div
            className="w-full flex -ml-3 items-center"
            onClick={() => navigate(allRoutes.SETTINGS.PROFILE.buildPath({}))}
          >
            <FontAwesomeIcon icon={faAngleLeft} fixedWidth size="2x" color="white" />
            <Text as="h3" size="xs" weight="semibold" className="pt-0">
              Privacy Settings
            </Text>
          </div>
          {/* <ProfilePrivacySettings /> */}
          {/* <NotificationSettings /> */}
          {/* <ShowCandidTeamInFeedSettings /> */}
          {/* <NotificationSection /> */}
          <Text as="h3" size="xs" weight="semibold" className="pt-2">
            Notifications
          </Text>
          <PushSettingsContainer />
          <EmailSettingsContainer />
          <SMSSettingsContainer />
          <ConnectionControlSection />

          <AccountManagement />
          <div className="sm:w-2/5 pb-3 text-sm flex-col justify-between w-3/5 text-white">
            <div className="flex-0 underline">
              <a
                // className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
                href="https://simmr.co/terms"
              >
                Terms & Conditions
              </a>
            </div>
            <div className="flex-0 underline">
              <a
                // className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
                href="http://simmr.co/privacy"
              >
                Privacy Policy
              </a>
            </div>

            {/* <div className="flex-0 underline">
              <Link
                // className={`btn max-lg:btn-sm btn-primary rounded-full capitalize`}
                to={allRoutes.privacy.buildPath({})}
              >
                Community Guidelines
              </Link>
            </div> */}

            {/* <div className="flex-0 underline">
              <a href="https://trustcandid.typeform.com/feedbackform">Contact Us</a>
            </div> */}
          </div>
        </>
      ) : (
        <div>
          <EditProfileSettingBubble
            text={fieldToEdit.field ?? ""}
            value={fieldToEdit.value}
            options={fieldToEdit?.options || []}
            description={fieldToEdit.description || ""}
            onBack={() => {
              setHidden(false);
              setFieldToEdit({
                field: null,
                value: null,
                handleUpdate: async () => Promise.resolve(),
              });
            }}
            onSave={async (updatedValue: string) => {
              setHidden(false);
              await fieldToEdit.handleUpdate(updatedValue as Profile_Privacy_Levels_Enum);
              setFieldToEdit({
                field: null,
                value: null,
                handleUpdate: async () => Promise.resolve(),
              });
              return Promise.resolve();
            }}
          />
        </div>
      )}
    </Container>
  );
}

export function PushSettingsContainer(): React.JSX.Element {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  // Toggle the NotificationSettings visibility
  const toggleNotifications = () => {
    setIsNotificationVisible((prev) => !prev);
  };

  return (
    <div className="flex flex-col">
      {/* Profile Setting Bubble */}
      <ProfileSettingBubble
        text="Push"
        value={
          <FontAwesomeIcon
            icon={isNotificationVisible ? faChevronDown : faChevronRight}
            fixedWidth
            size="lg"
            className="text-white"
          />
        }
        handleClick={toggleNotifications}
        isExpanded={isNotificationVisible}
      />

      {/* Conditionally render NotificationSettings */}
      {isNotificationVisible && (
        <div className="mt-2">
          {/* <NotificationSettings /> */}
          <PushNotificationSettings />
        </div>
      )}
    </div>
  );
}

export function EmailSettingsContainer(): React.JSX.Element {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  // Toggle the NotificationSettings visibility
  const toggleNotifications = () => {
    setIsNotificationVisible((prev) => !prev);
  };

  return (
    <div className="flex flex-col">
      {/* Profile Setting Bubble */}
      <ProfileSettingBubble
        text="Email"
        value={
          <FontAwesomeIcon
            icon={isNotificationVisible ? faChevronDown : faChevronRight}
            fixedWidth
            size="lg"
            className="text-white"
          />
        }
        handleClick={toggleNotifications}
        isExpanded={isNotificationVisible}
      />

      {/* Conditionally render NotificationSettings */}
      {isNotificationVisible && (
        <div className="mt-2">
          {/* <NotificationSettings /> */}
          <EmailNotificationSettings />
        </div>
      )}
    </div>
  );
}

export function SMSSettingsContainer(): React.JSX.Element {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  // Toggle the NotificationSettings visibility
  const toggleNotifications = () => {
    setIsNotificationVisible((prev) => !prev);
  };

  return (
    <div className="flex flex-col">
      {/* Profile Setting Bubble */}
      <ProfileSettingBubble
        text="SMS"
        value={
          <FontAwesomeIcon
            icon={isNotificationVisible ? faChevronDown : faChevronRight}
            fixedWidth
            size="lg"
            className="text-white"
          />
        }
        handleClick={toggleNotifications}
        isExpanded={isNotificationVisible}
      />

      {/* Conditionally render NotificationSettings */}
      {isNotificationVisible && (
        <div className="mt-2">
          {/* <NotificationSettings /> */}
          <SmsNotificationSettings />
        </div>
      )}
    </div>
  );
}
// function NotificationSection({}: {}): React.JSX.Element {
//   const navigate = useNavigate();
//   return (
//     <>
//       <Text as="h3" size="xs" weight="semibold" className="pt-2">
//         Notifications
//       </Text>

//       <ProfileSettingBubble
//         text="Push"
//         handleClick={() =>{}}
//       />
//       <NotificationSettings />
//       <ProfileSettingBubble
//         text="Email"
//         handleClick={() => navigate(allRoutes.SETTINGS.DATING.buildPath({}))}
//       />
//     </>
//   );
// }

function ConnectionControlSection({}: {}): React.JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      <Text as="h3" size="xs" weight="semibold" className="pt-2">
        Connection Controls
      </Text>

      <ProfileSettingBubble
        text="Blocked Accounts"
        handleClick={() => navigate(allRoutes.SETTINGS.BLOCKED.buildPath({}))}
      />
      {/* <ProfileSettingBubble
        text="Restricted Accounts"
        handleClick={() => navigate(allRoutes.SETTINGS.PRIVACY.buildPath({}))}
      /> */}
      <ProfileSettingBubble
        text="Hidden Profiles"
        handleClick={() => navigate(allRoutes.SETTINGS.HIDDEN.buildPath({}))}
      />
    </>
  );
}

// function Privacy({}: {}): React.JSX.Element {
//   const navigate = useNavigate();
//   return (
//     <>
//       <Text as="h3" size="xs" weight="semibold" className="pt-2">
//         Privacy
//       </Text>

//       <ProfileSettingBubble
//         text="Invisibility Mode"
//         handleClick={() => navigate(allRoutes.SETTINGS.PRIVACY.buildPath({}))}
//       />
//     </>
//   );
// }

function AccountManagement({}: {}): React.JSX.Element {
  const navigate = useNavigate();
  const [closeAccountMutation, { loading }] = useDeleteMyAccountMutation();
  const [pauseAccountMutation, { loading: pauseLoading }] = usePauseUserMutation();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<{
    title: string;
    content: string;
    action: () => Promise<void>;
  } | null>(null);

  const my_id = useMustBeLoggedIn();
  const addToast = useAddToast();

  const handlePauseorCloseAccount = async ({ action }: { action: "pause" | "delete" }) => {
    if (!my_id) return;
    // User facing options are delete or pause but both result in a soft delete in the backend
    await closeAccountMutation({ variables: { user_id: my_id } });
    if (action === "pause") {
      addToast({ content: "Your account is now paused", color: "success" });
      mixpanel.track("User Paused Account");
    } else {
      addToast({ content: "Deleted your account", color: "success" });
      mixpanel.track("User soft deleted account");
    }
    navigate(allRoutes.logout.buildPath({}));
  };

  const openPauseAccountModal = () => {
    setModalContent({
      title: "Taking a break?",
      content:
        "Pausing your account keeps your data and connections safe, so you can return anytime without starting over.",
      action: () => handlePauseorCloseAccount({ action: "pause" }),
    });
    setModalVisible(true);
  };

  const openCloseAccountModal = () => {
    setModalContent({
      title: "Are you sure?",
      content:
        "Not sure about continuing? Pause your account instead of closing it! This way, you can take a break without losing your data or connections, making it easy to pick up right where you left off if you decide to return.",
      action: () => handlePauseorCloseAccount({ action: "delete" }),
    });
    setModalVisible(true);
  };

  return (
    <>
      <Text as="h3" size="xs" weight="semibold" className="pt-2">
        Account Management
      </Text>
      <Modal isOpen={modalVisible} onDismiss={() => setModalVisible(false)}>
        <div className="flex flex-col gap-2 text-center">
          <Text as="h3" size="sm" weight="semibold">
            {modalContent?.title}
          </Text>
          <Text as="p" size="xxs" className="py-2">
            {modalContent?.content}
          </Text>

          {/* Cancel button that swaps modal content to Pause Account */}
          <Button
            onClick={() => {
              if (modalContent?.title === "Are you sure?") {
                openPauseAccountModal();
              } else {
                setModalVisible(false);
              }
            }}
            className="!bg-[#FF625F]" // for active state
            variant="standard"
          >
            {modalContent?.title === "Are you sure?" ? "Pause Account" : "Cancel"}
          </Button>
          <div />
          {/* Main action button */}
          <Button
            onClick={async () => {
              if (modalContent?.action) {
                await modalContent.action();
              }
              setModalVisible(false);
            }}
            variant="outline"
          >
            {modalContent?.title === "Taking a break?"
              ? "Pause Account"
              : "Close account permanently"}
          </Button>
        </div>
      </Modal>

      {/* Buttons to trigger modals */}
      <ProfileSettingBubble text="Pause Account" handleClick={openPauseAccountModal} />
      <ProfileSettingBubble text="Close Account" handleClick={openCloseAccountModal} />
    </>
  );
}

import {
  L_News_Feed_Type,
  useAllMyRelationshipsProfileImageHashData410Query,
  useDiscoveryResults300Query,
  useNewsFeed440Query,
  useUserPreferencesQuery,
  useUserThreads540Query,
} from "shared/dist/__generated__/components";
import {
  useGenderListQuery,
  useMyEnabledFeaturesQuery,
  useVouchInteractionTypesQuery,
} from "shared/dist/__generated__/components";

import { ErrorBoundary } from "react-error-boundary";
import React from "react";
import { allRoutes } from "./routes";
import { match } from "ts-pattern";
import { useLoadImageWithCache } from "shared-web-react/dist/widgets/lazy-image";
import { useLoadMediaItemIntoCache } from "../widgets/lazy-media-item";
import { useMkImgUrl } from "../util/routes";
import { useMkUrl } from "shared/dist/util/env";
import { useMyId } from "shared/dist/auth-data";
import { useProfileImageUrl } from "shared-web-react/dist/util/url-helpers";

export function QueryCacheHydrator(): React.JSX.Element {
  return (
    <ErrorBoundary
      FallbackComponent={() => <></>}
      onError={(err) => {
        console.error("🚀 - file: query-cache-hydrator.tsx:29 - QueryCacheHydrator - err:", err);
      }}
    >
      <QueryCacheHydratorInner />
    </ErrorBoundary>
  );
}

function QueryCacheHydratorInner() {
  const my_id = useMyId()!;
  const {} = useUserPreferencesQuery({
    skip: !my_id,
    variables: { my_id },
    fetchPolicy: "cache-first",
  });
  const {} = useAllMyRelationshipsProfileImageHashData410Query({
    variables: { my_id },
    skip: !my_id || true,
    fetchPolicy: "cache-first",
  });
  const {} = useVouchInteractionTypesQuery({ skip: !my_id, fetchPolicy: "cache-first" });
  const {} = useGenderListQuery({ skip: !my_id, fetchPolicy: "cache-first" });
  const {} = useUserThreads540Query({
    skip: !my_id,
    variables: { limit: 100, my_id: my_id },
    fetchPolicy: "cache-and-network",
  });
  const {} = useMyEnabledFeaturesQuery({
    skip: !my_id,
    variables: { my_id },
    fetchPolicy: "cache-first",
  });
  useNewsFeedHydrator();

  return (
    <>
      <DiscoveryImagesHydrator page={0} />
    </>
  );
}

function useNewsFeedHydrator() {
  const loadMediaItem = useLoadMediaItemIntoCache();
  const { loadImage: loadUrl } = useLoadImageWithCache();
  const mkUrl = useMkUrl();
  const [loadedImages, setLoadedImages] = React.useState(false);
  const { loading, data } = useNewsFeed440Query({
    variables: {
      filter: { feed_type: L_News_Feed_Type.DiscoveryOnly },
    },
    fetchPolicy: "cache-first",
  });
  const items = match(data?.l_news_feed)
    .with({ __typename: "L_News_Feed_Response_Success" }, (data) => data.items)
    .otherwise(() => []);

  React.useEffect(() => {
    if (loadedImages || loading) return;
    // console.log(
    //   "🚀 - file: query-cache-hydrator.tsx:69 - Hydrating News Feed",
    //   loadedImages,
    //   mkUrl("test-url")
    // );
    setLoadedImages(true);
    items.forEach((item) =>
      match(item)
        .with({ __typename: "L_News_Feed_Item_Media" }, (item) => loadMediaItem(item.id, "1280"))
        .with({ __typename: "L_News_Feed_Item_Event" }, (item) => {
          const path = allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({ slug: item.event_slug });
          loadUrl(mkUrl(path));
        })
        .otherwise(() => null)
    );
  }, [loadedImages, setLoadedImages, items]);
}

function DiscoveryImagesHydrator({ page }: { page: number }): React.JSX.Element {
  const { data: discoveryData } = useDiscoveryResults300Query({
    variables: {
      use_dummy_data: false,
      page,
    },
    fetchPolicy: "cache-first",
  });
  const data = match(discoveryData?.l_discovery)
    .with({ __typename: "L_Discovery_Response_Success" }, (data) => data.items)
    .otherwise(() => []);
  return (
    <span className="hidden">
      {data.map((item, idx) => (
        <DiscoverySingleImageHydrator slug={item.user.user_slug} key={idx} />
      ))}
    </span>
  );
}

function DiscoverySingleImageHydrator({ slug }: { slug: string }): React.JSX.Element {
  const [isPending, startTransition] = React.useTransition();
  const { mediaUploadId, url } = useProfileImageUrl(slug);
  const [loadedData, setLoadedData] = React.useState(false);
  const loadMediaItem = useLoadMediaItemIntoCache();
  const { loadImage: loadUrl } = useLoadImageWithCache();
  React.useEffect(() => {
    if (!url || loadedData || !mediaUploadId || isPending) return;
    (() => {
      console.count("🚀 - file: query-cache-hydrator.tsx:81 - React.useEffect - url: " + url);
      loadMediaItem(mediaUploadId, "256");
      loadUrl(url);
      setLoadedData(true);
    })();
  }, [
    isPending,
    mediaUploadId,
    startTransition,
    loadedData,
    setLoadedData,
    loadMediaItem,
    loadUrl,
    url,
  ]);
  return <></>;
}

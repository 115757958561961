/*
 * This module provides functions to persist a pagination cursor across navigations.
 * The cursor is stored in localStorage along with a timestamp.
 * It remains valid for 6 hours (21600000 milliseconds).
 */

interface CursorRecord {
  cursor: string;
  timestamp: number;
  feedType: string; // Add feed type to differentiate cursors
}

function getCursorKey(feedType: string): string {
  return `newsFeedCursor_${feedType}`;
}

const VALID_DURATION = 6 * 60 * 60 * 1000; // 6 hours in milliseconds

export function saveCursor(cursor: string, feedType: string = "default"): void {
  console.log(`Saving cursor for ${feedType}:`, cursor);
  const record: CursorRecord = {
    cursor,
    timestamp: Date.now(),
    feedType,
  };
  try {
    localStorage.setItem(getCursorKey(feedType), JSON.stringify(record));
  } catch (e) {
    console.error("Failed to save cursor:", e);
  }
}

export function loadCursor(feedType: string = "default"): string | null {
  try {
    const json = localStorage.getItem(getCursorKey(feedType));
    if (!json) {
      console.log("No cursor found in storage for feed type:", feedType);
      return null;
    }

    const record: CursorRecord = JSON.parse(json);
    console.log(`Loading cursor for ${feedType}:`, record);

    // Check if cursor matches feed type
    if (record.feedType !== feedType) {
      console.log("Cursor feed type mismatch, clearing for feed type:", feedType);
      localStorage.removeItem(getCursorKey(feedType));
      return null;
    }

    // Check if cursor has expired
    if (Date.now() - record.timestamp >= VALID_DURATION) {
      console.log("Cursor expired, clearing for feed type:", feedType);
      localStorage.removeItem(getCursorKey(feedType));
      return null;
    }

    return record.cursor;
  } catch (e) {
    console.error("Failed to load cursor:", e);
    localStorage.removeItem(getCursorKey(feedType));
    return null;
  }
}

export function clearCursor(feedType: string = "default"): void {
  localStorage.removeItem(getCursorKey(feedType));
}

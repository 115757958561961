import {
  GqlOps,
  useAddDiscoveryGenderPreferenceMutation,
  useUnsetDiscoveryGenderPreferenceMutation,
  useGenderListQuery,
} from "shared/dist/__generated__/components";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { ContentBox } from "../../../../widgets/content-box";
import { DiscoveryCommonProps } from "./dating-settings-utilities";
import { GenderPills } from "../../../../widgets/pickers/gender-picker";
import { H5 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { classNames } from "shared/dist/util";

// function GenderPreferencePicker({
//   partner,
//   hasPartners,
// }: {
//   partner?: PartnerData;
//   hasPartners?: boolean;
// }): React.JSX.Element {
//   return (
//     <div className={classNames("my-2")}>
//       <GenderPills
//         showCouples
//         bottomLabel={
//           hasPartners && !partner
//             ? "Leave blank to only match with your partners"
//             : undefined
//         }
//         topLabel={
//           !(hasPartners || partner) ? undefined : partner ? (
//             <div className={classNames("-mb-2 mt-2")}>
//               For matching with{" "}
//               <Avatar slug={partner.slug} tailwindSize="4" className="mx-1" />
//               <span className={classNames("font-bold")}>{partner.slug}</span>
//             </div>
//           ) : (
//             <div className={classNames("-mb-2 mt-2")}>For me</div>
//           )
//         }
//       />
//     </div>
//   );
// }

export function GenderPreferences({
  prefs,
  hasPartners,
  partner,
  disabled,
}: {
  hasPartners?: boolean;
} & DiscoveryCommonProps): React.JSX.Element {
  const [addGenderPref] = useAddDiscoveryGenderPreferenceMutation();
  const [removeGenderPref] = useUnsetDiscoveryGenderPreferenceMutation();
  const { data: genderListData } = useGenderListQuery();

  // Local state for gender preferences
  const dbGenders = prefs?.genders?.map((g) => g.gender_id) ?? [];
  const [localGenders, setLocalGenders] = React.useState(dbGenders);
  const [isInitializing, setIsInitializing] = React.useState(false);

  // Auto-initialize gender preferences if none exist
  React.useEffect(() => {
    const initializeAllGenderPreferences = async () => {
      if (!prefs?.id || isInitializing || dbGenders.length > 0 || disabled) {
        return;
      }

      try {
        setIsInitializing(true);
        console.log("No gender preferences found, initializing all genders");

        const allGenders = genderListData?.genders ?? [];
        if (allGenders.length === 0) {
          console.warn("No genders found in gender list query");
          setIsInitializing(false);
          return;
        }

        // Add all gender preferences in parallel
        const genderPromises = allGenders.map((gender) =>
          addGenderPref({
            variables: { id: prefs.id, gender_id: gender.id },
            refetchQueries: [
              GqlOps.Query.GetAllDiscoveryPreferences300,
              GqlOps.Query.GetDiscoveryPreferences300,
            ],
          })
        );

        // Also add the "Couples" option
        genderPromises.push(
          addGenderPref({
            variables: { id: prefs.id, gender_id: "couples" },
            refetchQueries: [
              GqlOps.Query.GetAllDiscoveryPreferences300,
              GqlOps.Query.GetDiscoveryPreferences300,
            ],
          })
        );

        // Wait for all gender preferences to be added
        await Promise.all(genderPromises);

        // Update local state with all genders
        const allGenderIds = [...allGenders.map((g) => g.id), "couples"];
        setLocalGenders(allGenderIds);
      } catch (error) {
        console.error("Error initializing gender preferences:", error);
      } finally {
        setIsInitializing(false);
      }
    };

    initializeAllGenderPreferences();
  }, [
    prefs?.id,
    dbGenders.length,
    genderListData?.genders,
    addGenderPref,
    disabled,
    isInitializing,
  ]);

  // Sync local state with backend data if they differ
  React.useEffect(() => {
    if (!isInitializing && JSON.stringify(localGenders) !== JSON.stringify(dbGenders)) {
      setLocalGenders(dbGenders);
    }
  }, [dbGenders, localGenders, isInitializing]);

  const toggleGender = React.useCallback(
    async (choice: string, newState: boolean) => {
      const id = prefs?.id;
      if (!id || disabled) return;

      const variables = { id, gender_id: choice };
      const mutation = newState ? addGenderPref : removeGenderPref;

      // Optimistic update
      setLocalGenders((prev) => (newState ? [...prev, choice] : prev.filter((g) => g !== choice)));

      await mutation({
        variables,
        refetchQueries: [
          GqlOps.Query.GetAllDiscoveryPreferences300,
          GqlOps.Query.GetDiscoveryPreferences300,
        ],
      });
    },
    [prefs, addGenderPref, removeGenderPref, disabled]
  );

  return (
    <div>
      <div className="mb-4">
        <p className="text-sm text-gray-500">
          Select the genders you're interested in. Select "Couples" to include relationship profiles
          in your discovery feed along with individual profiles.
        </p>
      </div>
      {isInitializing ? (
        <div className="text-sm text-gray-500 italic">Initializing gender preferences...</div>
      ) : (
        <GenderPills
          showCouples
          disabled={disabled || isInitializing}
          defaultValue={localGenders}
          onChange={async (active, selection, newStatus) => {
            toggleGender(selection.value, newStatus);
          }}
        />
      )}
    </div>
  );
}

import { Maybe, filterNulls, notNullish, sortBy, typedFromEntries, uniqBy } from "shared/dist/util";
import { ProfileList, ProfileListItem } from "../../widgets/profile-list";
import {
  Thread_List_Details_554Fragment,
  useUserThreads554Query,
} from "shared/dist/__generated__/components";
import { faFaceParty, faImage } from "@fortawesome/pro-solid-svg-icons";

import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../util/routes";
import clsx from "clsx";
import { useMyId } from "shared/dist/auth-data";
import { SimmerZeroScreen } from "../../widgets/zero-screens";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { LazyImage } from "shared-web-react/dist/widgets/lazy-image";
import { useMkUrl } from "shared/dist/util/env";
import { Text } from "../../components/atoms/Text";
import { useOptimalImageSize } from "../../util/optimized-image-sizes";
import simmerHeart from "../../assets/Feed-Active.png";

type ProfileListItemWithCount = ProfileListItem & { member_count: number };
type ThreadData = Thread_List_Details_554Fragment;

function threadToListItem(
  myId: string,
  mkUrl: (path: string) => string,
  optimalConnectionImageSize: string
) {
  return (
    thread: Maybe<ThreadData>
  ): null | { thread_id: string; last_msg_dt?: DateTime; item: ProfileListItemWithCount } => {
    if (!thread) return null;

    const unblockedMembers = thread.members?.filter(
      (member) =>
        !(member.member_id === myId) &&
        !member.user_public?.this_user_blocked_by_users?.length &&
        !member.user_public?.users_blocked_by_this_user?.length
    ).length;
    if (unblockedMembers < 1 && !thread.event?.id) return null;

    const msg = thread?.messages?.[0];
    const caption = (
      <span>
        {msg?.message_media_uploads?.[0] && (
          <FontAwesomeIcon icon={faImage} className={clsx("mr-2")} />
        )}
        {msg?.content?.slice(0, 200)}
      </span>
    );
    const path = allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({
      slug: thread.event?.url_slug ?? "",
    });

    const eventImgUrl =
      mkUrl(path) + (thread.event?.media_upload_id ? `?mid=${thread.event?.media_upload_id}` : "");

    // Process the image URL with the optimal size that was passed in
    let optimizedEventImgUrl = eventImgUrl;
    if (eventImgUrl) {
      // If the URL already contains a size parameter, replace it
      if (eventImgUrl.includes("size=")) {
        optimizedEventImgUrl = eventImgUrl.replace(
          /size=\w+/,
          `size=${optimalConnectionImageSize}`
        );
      } else {
        // Otherwise, add the size parameter
        const separator = eventImgUrl.includes("?") ? "&" : "?";
        optimizedEventImgUrl = `${eventImgUrl}${separator}size=${optimalConnectionImageSize}`;
      }
    }

    return {
      thread_id: thread.id,
      last_msg_dt: thread.messages?.[0]?.created_at
        ? DateTime.fromISO(thread.messages?.[0]?.created_at)
        : undefined,
      item: {
        avatarOverride: thread.event?.id ? (
          <div className="w-full flex h-full justify-center items-center">
            <div
              className={clsx(
                ` avatar w-${12} h-${12} flex-col-center-center aspect-square rounded-full`,
                ` bg-cover bg-no-repeat bg-center shadow-md `
              )}
            >
              <LazyImage
                src={optimizedEventImgUrl}
                alt="avatar"
                className="w-full h-full rounded-full aspect-square bg-white"
                imgClassName="aspect-square"
                defaultImageUrl="/assets/default-avatar.png"
                onError={(e) => {
                  console.error("Failed to load image:", optimizedEventImgUrl);
                  // Fall back to default size if optimized image fails to load
                  if (e && optimizedEventImgUrl.includes("size=")) {
                    const fallbackUrl = optimizedEventImgUrl.replace(/size=\w+/, "size=md");
                    e.currentTarget.src = fallbackUrl;
                  }
                }}
              />
            </div>
          </div>
        ) : undefined,
        caption,
        subCaption: thread?.messages?.[0]?.created_at
          ? DateTime.fromISO(thread?.messages?.[0]?.created_at).toLocaleString(
              DateTime.DATETIME_SHORT
            )
          : undefined,
        slug: thread?.members?.map((m) => m.user_public?.slug)?.filter(Boolean)?.[0],
        screenName:
          thread.event?.name ??
          (thread?.members?.map((m) => m.user_public?.screen_name)?.filter(Boolean) ?? []).join(
            ", "
          ),
        member_count: thread.members?.length ?? 0, // Add member_count here
      },
    };
  };
}

export function ThreadList(): React.JSX.Element {
  const myId = useMyId()!;
  const mkUrl = useMkUrl();
  const optimalConnectionImageSize = useOptimalImageSize("CONNECTION");
  const { data, loading } = useUserThreads554Query({
    skip: !myId,
    variables: { limit: 100, my_id: myId },
    fetchPolicy: "cache-and-network",
    pollInterval: 1000 * 60,
  });
  const navigate = useNavigate();
  const threads = filterNulls([
    ...(data?.event_attendees?.flatMap((ea) => ea.event?.threads) ?? []),
    ...(data?.thread_members?.map((tm) => tm.thread) ?? []),
    ...(data?.events?.flatMap((tm) => tm.threads) ?? []),
  ]).filter((t) => t.event?.id || !!t.messages?.length);
  const eventCreationDates = typedFromEntries([
    ...filterNulls(
      data?.event_attendees?.map((ea) =>
        ea.event
          ? ([ea.event.threads?.[0].id, DateTime.fromISO(ea.event.created_at)] as [
              string,
              DateTime,
            ])
          : null
      )
    ),
    ...filterNulls(
      data?.events?.map(
        (ea) => [ea.threads[0]?.id, DateTime.fromISO(ea.created_at)] as [string, DateTime]
      )
    ),
  ]);
  const threadListItems: Array<{
    item: ProfileListItemWithCount;
    thread_id: string;
  }> = React.useMemo(
    () =>
      sortBy(
        uniqBy(
          filterNulls(threads.map(threadToListItem(myId, mkUrl, optimalConnectionImageSize))),
          (t) => t.thread_id
        ),
        (t) => -1 * (t.last_msg_dt?.toMillis() ?? eventCreationDates[t.thread_id]?.toMillis() ?? 0)
      ),
    [data, optimalConnectionImageSize]
  );

  const unreadSet = React.useMemo(
    () =>
      new Set(
        threads
          ?.filter(
            (thread) =>
              thread.messages?.[0]?.sender_id !== myId &&
              (!thread.last_viewed_at?.[0]?.last_viewed_at ||
                thread.messages?.[0]?.created_at > thread.last_viewed_at?.[0]?.last_viewed_at)
          )
          .map((t) => t.id)
      ),
    [data]
  );
  return (
    <div className="overflow-y-auto pb-10 max-h-full bg-[#2A2C38]">
      {loading && !threadListItems?.length ? (
        <SpinnerCentered />
      ) : threadListItems.length > 0 ? (
        threadListItems.map(({ item, thread_id }) => (
          <React.Fragment key={thread_id}>
            <Link
              to={allRoutes.MESSAGING.LIST.THREAD.buildPath({
                thread_id: thread_id ?? "foo",
              })}
              className="grid gap-2 m-2 px-4 items-center"
              style={{
                gridTemplateColumns: "15% 55% 25% 5%",
              }}
            >
              {item.member_count == 1 && <Avatar slug={item.slug!} tailwindSize="14" />}
              {/* handle default for events */}
              {item.member_count !== 1 && simmerHeart && (
                <div
                  className={clsx(
                    ` avatar w-${12} h-${12} flex-col-center-center aspect-square rounded-full`,
                    ` bg-cover bg-no-repeat bg-center shadow-md `
                  )}
                >
                  <LazyImage
                    src={simmerHeart}
                    // thumbHash={thumbHash}
                    alt="avatar"
                    className="w-full h-full p-2 rounded-full bg-white"
                    imgClassName="aspect-square"
                  />
                </div>
              )}{" "}
              <div className="px-4">
                <Text as="h2" size="xs">
                  {item.screenName}
                </Text>
                <span className="flex flex-col gap-2 w-full self-start">
                  <Text
                    as="p"
                    size="xxs"
                    className="overflow-ellipsis overflow-hidden whitespace-nowrap"
                  >
                    {typeof item.caption === "string" && item.caption.length > 15
                      ? `${item.caption.slice(0, 15)}...`
                      : item.caption}
                  </Text>
                </span>
              </div>
              <Text as="p" size="tiny" weight="light" className="place-self-center">
                {item.subCaption
                  ? DateTime.fromFormat(item.subCaption, "M/d/yyyy, h:mm a").toRelative({
                      style: "short",
                    })
                  : ""}{" "}
                {/* {threadListItems[0]?.item.subCaption} */}
              </Text>
              <div
                className={clsx(
                  "z-10 left-2 rounded-full place-self-center h-[9px] w-[9px]",
                  unreadSet.has(thread_id) ? "bg-red-500" : "bg-[#2A2C38]"
                )}
              ></div>
            </Link>
            <div className="h-px bg-white/50" />
          </React.Fragment>
        ))
      ) : (
        <div className="text-white py-2 px-4 text-lg font-semibold text-center">
          <SimmerZeroScreen
            btnText="Message Connections"
            text="You're up to date! Go ahead and connect with like-minded people."
            header="No Current Messages"
            clickHandler={() => navigate(allRoutes.DISCOVERY.MATCHES.buildPath({}))}
          />{" "}
        </div>
      )}
    </div>
  );
}

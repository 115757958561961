import { ContentBox, ContentBoxInfoLine } from "../../../../widgets/content-box";
import {
  GqlOps,
  Relationship_Types_Enum,
  useMyRelationshipsShortV410Query,
  usePartnershipLinksQuery,
  useRegeneratePartnershipLinkMutation,
} from "shared/dist/__generated__/components";
import { ProfileListHorizontal, ProfileListItem } from "../../../../widgets/profile-list";
import { allRoutes, useMustBeLoggedIn } from "../../../../util/routes";
import {
  faCircleI,
  faUpFromBracket,
  faAngleDown,
  faAngleRight,
  faArrowsRotate,
} from "@fortawesome/pro-solid-svg-icons";
import { useMkUrl, useOrigin } from "shared/dist/util/env";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { filterNulls } from "shared/dist/util";
import { useShare } from "../../../../widgets/share";
import { ProfileSettingBubble } from "../profile";
import { Text } from "../../../../components/atoms/Text";
import { Button } from "shared-web-react/dist/widgets/Button";

export function PartnerSettings(): React.JSX.Element {
  const [link, regenerateLink] = usePartnerLink();
  // Always start expanded for now to make sure it's visible
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mb-4">
      <ProfileSettingBubble
        text="Partner Settings"
        handleClick={toggleExpand}
        isExpanded={isExpanded}
      />

      {isExpanded && (
        <div className="mt-2 px-4 py-3 bg-[#3A3B44] rounded-lg">
          {link ? (
            <div className="mb-3">
              <div className="flex items-center mb-3">
                <FontAwesomeIcon icon={faCircleI} className="mr-2 text-gray-300" />
                <Text as="p" size="xxs" className="text-white font-medium">
                  Share this link to add romantic partners on Simmr
                </Text>
              </div>

              <div className="flex flex-col gap-2">
                {/* Link preview removed as requested */}
                <div className="flex gap-2">
                  <PartnerLink link={link} />
                  {/* <RegenerateButton onRegenerate={regenerateLink} /> */}
                </div>
              </div>
            </div>
          ) : (
            <SpinnerCentered />
          )}

          <PartnerList />
        </div>
      )}
    </div>
  );
}

function PartnerLink({ link }: { link: string }): React.JSX.Element {
  const share = useShare();
  const copy = () => {
    const text = `I want to add you as my relationship partner on Simmr! ${link}`;
    console.log("🚀 ~ file: partner-settings.tsx:53 ~ copy ~ link:", link);
    share({
      title: `Simmr!`,
      text,
      fallbackText: text,
    });
  };
  return (
    <Button
      onClick={copy}
      className="flex-1 bg-[#464752] text-white font-medium py-2 rounded-lg flex items-center justify-center hover:bg-[#53545f] transition-colors"
    >
      <FontAwesomeIcon icon={faUpFromBracket} className="mr-2" />
      Share Link
    </Button>
  );
}

function RegenerateButton({
  onRegenerate,
}: {
  onRegenerate: () => Promise<void>;
}): React.JSX.Element {
  return (
    <Button
      onClick={onRegenerate}
      className="bg-[#464752] text-white font-medium py-2 rounded-lg flex items-center justify-center hover:bg-[#53545f] transition-colors"
    >
      <FontAwesomeIcon icon={faArrowsRotate} />
    </Button>
  );
}

function PartnerList({}: {}): React.JSX.Element {
  const my_id = useMustBeLoggedIn();

  const { data, loading } = useMyRelationshipsShortV410Query({
    variables: { my_id: my_id as string, type: Relationship_Types_Enum.Partner },
    skip: !my_id,
  });

  const partners = filterNulls(
    (data?.relationships_union ?? []).map((p) =>
      p?.snd_user_summary?.slug && p?.snd_user_summary?.screen_name
        ? {
            slug: p?.snd_user_summary?.slug,
            screenName: p?.snd_user_summary?.screen_name,
          }
        : null
    )
  );

  if (loading) {
    return <SpinnerCentered />;
  }

  return partners.length ? (
    <div>
      <Text as="p" size="xxs" className="text-white font-medium mb-2 mt-3">
        Your Partners
      </Text>
      <ProfileListHorizontal columnLayout profiles={partners} />
    </div>
  ) : (
    <Text as="p" size="xxs" className="text-gray-300 italic">
      You don't have any partners yet. Share your link to add partners.
    </Text>
  );
}

function usePartnerLink(): [string | null, () => Promise<void>] {
  const my_id = useMustBeLoggedIn();

  const { data, loading, called } = usePartnershipLinksQuery({
    variables: { my_id: my_id as string },
    skip: !my_id,
  });

  const [mutate] = useRegeneratePartnershipLinkMutation();

  React.useEffect(() => {
    if (loading) { return; } // prettier-ignore
    if (data === undefined) { return; } //  prettier-ignore
    if (data?.relationship_invitations_by_pk === null && my_id) {
      mutate({
        refetchQueries: [GqlOps.Query.PartnershipLinks],
        variables: { my_id: my_id as string },
      });
    }
  }, [data, loading, called, my_id]);

  const invitation_code = data?.relationship_invitations_by_pk?.invitation_code;
  const mkUrl = useMkUrl();
  const link = invitation_code
    ? mkUrl(
        allRoutes.RELATIONSHIP_INVITE.buildPath({
          invitation_code,
        })
      )
    : null;

  return [
    link,
    async () => {
      if (my_id) {
        await mutate({ variables: { my_id: my_id as string } });
      }
    },
  ];
}

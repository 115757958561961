import { BasicLocations, LocationPicker, useSaveLocation } from "../../widgets/bio/location-picker";

import { L_Geo_Point_Type } from "shared/dist/__generated__/components";
import { Loc } from "shared/dist/util/loc";
import { ObScreen } from "./widgets";
import React from "react";
import clsx from "clsx";
import { useMustBeLoggedIn } from "../../util/routes";
import { Text } from "../../components/atoms/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../../components/atoms/Button";
import locationMap from "../../assets/location-map.png";
import mixpanel from "mixpanel-browser";

export function ObLocation({
  onNext,
  setDisableButton,
}: {
  onNext: () => void;
  setDisableButton?: (b: boolean) => void;
}): React.JSX.Element {
  const saveLocation = useSaveLocation({ source: L_Geo_Point_Type.Manual, suppressToast: true });
  const [customLocation, setCustomLocation] = React.useState<Loc | null>(null);
  const [locationSet, setLocationSet] = React.useState<boolean>(false);
  const [locationDescription, setLocationDescription] = React.useState<string | null>(null);

  // Force disable button on mount and when component unmounts
  React.useEffect(() => {
    console.log("Location screen mounted - disabling button");
    setDisableButton?.(true);

    // Ensure button stays disabled when component unmounts if no location was set
    return () => {
      if (!locationSet) {
        console.log("Location screen unmounted with no location - keeping button disabled");
        setDisableButton?.(true);
      }
    };
  }, []);

  // Track when this screen is viewed
  React.useEffect(() => {
    mixpanel.track("Onboarding Flow: Location entry");
  }, []);

  // Enhanced location handling - save location when selected
  React.useEffect(() => {
    if (!customLocation) return;

    console.log("Saving location:", customLocation);
    setDisableButton?.(true);

    saveLocation(customLocation)
      .then((result) => {
        console.log("Location saved successfully:", result);
        // Check if the location was saved successfully
        if (result?.data?.lm_geo_save_location?.__typename === "L_Geo_Save_Response") {
          // The backend returns the location description
          const description = (result.data.lm_geo_save_location as any).description;
          console.log("Location description set:", description);
          setLocationDescription(description);
          setLocationSet(true);
          setDisableButton?.(false);
        } else {
          console.error("Error saving location:", result?.data?.lm_geo_save_location);
          setDisableButton?.(true);
        }
      })
      .catch((error) => {
        console.error("Error saving location:", error);
        setDisableButton?.(true);
      });
  }, [customLocation, saveLocation, setDisableButton]);

  // Try to get user's location if they haven't selected one
  React.useEffect(() => {
    // Ensure button is disabled on every render until location is set
    if (!locationSet) {
      console.log("No location set - disabling button");
      setDisableButton?.(true);
    }

    const tryGetLocation = () => {
      if (navigator.geolocation && !locationSet) {
        console.log("Attempting to get geolocation");
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (!customLocation) {
              const loc = {
                lat: position.coords.latitude,
                long: position.coords.longitude,
              };
              console.log("Got geolocation:", loc);
              saveLocation(loc)
                .then((result) => {
                  if (result?.data?.lm_geo_save_location?.__typename === "L_Geo_Save_Response") {
                    // The backend returns the location description
                    const description = (result.data.lm_geo_save_location as any).description;
                    console.log("Geolocation saved successfully with description:", description);
                    setLocationDescription(description);
                    setLocationSet(true);
                    setDisableButton?.(false);
                  } else {
                    console.error("Error saving geolocation:", result?.data?.lm_geo_save_location);
                    setDisableButton?.(true);
                  }
                })
                .catch((err) => {
                  console.error("Error saving geolocation:", err);
                  setDisableButton?.(true);
                });
            }
          },
          (error) => {
            console.error("Geolocation error:", error);
            // Keep button disabled if we can't get location
            setDisableButton?.(true);
          }
        );
      }
    };

    // Wait a bit to see if user selects a location first
    const timeoutId = setTimeout(tryGetLocation, 3000);
    return () => clearTimeout(timeoutId);
  }, [customLocation, saveLocation, locationSet, setDisableButton]);

  return (
    <ObScreen title="Find other Simmrs in your city">
      <div className="flex flex-col h-full w-full pb-4 items-stretch">
        {/* Location Input */}
        <div className="form-control mb-4">
          <LocationPicker
            onSelect={(loc) => {
              // The LocationPicker returns a full L_Geo_Location object with label
              // Extract the lat/long for saving
              setCustomLocation({
                lat: loc.lat,
                long: loc.long,
              });
              // Don't enable the button yet - wait for the save to complete
            }}
            hideValueOnDisabled={false}
            hideIcon={false}
            placeholder="Enter city..."
            className="bg-white rounded-xl text-black px-4 py-2 placeholder-gray-400"
          />
        </div>

        {/* Map Placeholder */}
        <div className="relative flex items-center justify-center">
          <img src={locationMap} alt="Map placeholder" className="w-full rounded-xl object-cover" />
          <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" className="absolute text-red-500" />
        </div>

        <div className="flex-1 basis-full"></div>

        {!locationSet && (
          <Text className="text-center text-sm text-gray-400 mt-4">
            Please select a location to continue
          </Text>
        )}

        {locationSet && locationDescription && (
          <Text className="text-center text-sm text-green-400 mt-4">
            Location set to: {locationDescription}
          </Text>
        )}
      </div>
    </ObScreen>
  );
}

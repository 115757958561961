import {
  Feature_Flags_Enum,
  L_News_Feed_Type,
  useNewsFeed440Query,
} from "shared/dist/__generated__/components";
import { H2, H3 } from "shared-web-react/dist/widgets/text";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { classNames, sortBy } from "shared/dist/util";
import { useIsDev, useIsProd } from "shared/dist/util/env";
import { Container } from "../../widgets/container";
import { DateTime } from "luxon";
import { ErrorBoundary } from "react-error-boundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NewsFeedItem } from "./news-feed/items";
import React, { useContext, useRef } from "react";
import { VisibilityObserver } from "shared-web-react/dist/widgets/visibility-observer";
import clsx from "clsx";
import { faHome } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useOnScreenLogger } from "shared-web-react/dist/util/on-screen-logger";
import { useVirtualizer, Virtualizer } from "@tanstack/react-virtual";
import { SimmerZeroScreen } from "../../widgets/zero-screens";
import { Button } from "../../components/atoms/Button";
import { allRoutes } from "../../util/routes";
import { useNavigate } from "react-router-dom";
import { VirtualizerContext } from "../../util/virtualizer-context";
import ScrollToTopButton from "../../widgets/scroll-to-top";
import { loadCursor, saveCursor, clearCursor } from "../../util/cursor-store";

// Helper function to get cursor
const getCursor = (feedType: L_News_Feed_Type): string | null => {
  return loadCursor(feedType);
};

export function Home(): React.JSX.Element {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [useDummyData, setUseDummyData] = React.useState(false);
  const [feedType, setFeedType] = React.useState<L_News_Feed_Type>(L_News_Feed_Type.ConnectionsOnly);
  // Load initial cursor from storage
  const [currentCursor, setCurrentCursor] = React.useState<string | null>(() => {
    const saved = getCursor(feedType);
    console.log("Initial cursor load:", saved);
    return saved;
  });

  const { loading, data, error, fetchMore, refetch } = useNewsFeed440Query({
    variables: {
      filter: { feed_type: feedType },
      dummy_data: useDummyData,
      cursor: currentCursor,
    },
    fetchPolicy: "cache-first",
    onError: (error) => {
      console.error("News feed query error:", error);
    },
  });

  // Log state changes
  React.useEffect(() => {
    console.log("News feed state:", {
      loading,
      hasData: !!data,
      error: error?.message,
      feedType,
      currentCursor,
      resultType: data?.l_news_feed?.__typename,
      itemCount:
        data?.l_news_feed?.__typename === "L_News_Feed_Response_Success"
          ? data.l_news_feed.items?.length
          : 0,
    });
  }, [loading, data, error, feedType, currentCursor]);

  // Save cursor when we get new data
  React.useEffect(() => {
    if (data?.l_news_feed?.__typename === "L_News_Feed_Response_Success") {
      const newCursor = data.l_news_feed.next_page_cursor;
      // Only save and update if we have a new cursor that's different from current
      if (newCursor && newCursor !== currentCursor) {
        console.log("Saving new cursor from data:", newCursor, "for feed type:", feedType);
        saveCursor(newCursor, feedType);
        setCurrentCursor(newCursor);
      }
    }
  }, [data, feedType, currentCursor]);

  const [fetchingMore, setFetchingMore] = React.useState(false);
  const [reachedEnd, setReachedEnd] = React.useState(false);
  const [lastFetchedCursor, setLastFetchedCursor] = React.useState<string | null>(null);
  const [previousItemsCount, setPreviousItemsCount] = React.useState(0);
  const prevFeedType = useRef(feedType);
  const { setVirtualizer } = useContext(VirtualizerContext);
  const navigate = useNavigate();
  const isProd = useIsProd();
  const isDev = useIsDev();
  const newsFeedResult = data?.l_news_feed;
  const sorted = React.useMemo(
    () =>
      newsFeedResult?.__typename === "L_News_Feed_Response_Success"
        ? (newsFeedResult.items ?? [])
        : [],
    [newsFeedResult]
  );

  // Fetch more when reaching the bottom
  const onAtBottom = React.useCallback(
    async (atBottom: boolean) => {
      if (!atBottom || loading || fetchingMore || reachedEnd) return;

      // Additional checks to prevent infinite loading
      if (
        newsFeedResult?.__typename !== "L_News_Feed_Response_Success" ||
        !newsFeedResult.next_page_cursor ||
        // Prevent fetching if we're getting the same cursor repeatedly
        (newsFeedResult.__typename === "L_News_Feed_Response_Success" &&
          newsFeedResult.next_page_cursor === lastFetchedCursor)
      ) {
        if (
          newsFeedResult?.__typename === "L_News_Feed_Response_Success" &&
          newsFeedResult.next_page_cursor === lastFetchedCursor
        ) {
          console.log("Same cursor detected, marking as end of feed", lastFetchedCursor);
          setReachedEnd(true);
        }
        return;
      }

      setFetchingMore(true);
      // Store the cursor we're about to fetch
      setLastFetchedCursor(newsFeedResult.next_page_cursor);

      try {
        console.log("Fetching more with cursor:", newsFeedResult.next_page_cursor);
        const result = await fetchMore({
          variables: {
            cursor: newsFeedResult.next_page_cursor,
          },
        });

        // Update cursor after successful fetch
        if (result.data?.l_news_feed?.__typename === "L_News_Feed_Response_Success") {
          const newCursor = result.data.l_news_feed.next_page_cursor;
          const newItems = result.data.l_news_feed.items || [];
          const currentItemsCount = sorted.length;

          // Check if we got any new items
          if (newItems.length === 0) {
            console.log("No more items to fetch, reached end of feed");
            setReachedEnd(true);
            return;
          }

          // Check if the total items count hasn't changed after fetching
          if (currentItemsCount === previousItemsCount && previousItemsCount > 0) {
            console.log("No new items added after fetch, marking as end of feed");
            setReachedEnd(true);
            return;
          }

          // Update previous items count for next comparison
          setPreviousItemsCount(currentItemsCount);

          // Only save and update if we have a new cursor that's different from current
          if (newCursor && newCursor !== currentCursor) {
            console.log("Saving cursor after fetchMore:", newCursor, "for feed type:", feedType);
            saveCursor(newCursor, feedType);
            setCurrentCursor(newCursor);

            // If we get the same cursor back, we've reached the end
            if (newCursor === newsFeedResult.next_page_cursor) {
              console.log("Same cursor returned, marking as end of feed");
              setReachedEnd(true);
            }
          } else if (!newCursor) {
            // If we don't get a cursor back, we've reached the end
            console.log("No cursor returned, marking as end of feed");
            setReachedEnd(true);
          }
        }
      } finally {
        setFetchingMore(false);
      }
    },
    [
      loading,
      fetchingMore,
      reachedEnd,
      fetchMore,
      newsFeedResult,
      feedType,
      currentCursor,
      lastFetchedCursor,
      sorted.length,
      previousItemsCount,
    ]
  );

  const scrollParentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer<HTMLDivElement, Element>({
    count: sorted.length + 1,
    getScrollElement: () => scrollParentRef.current as HTMLDivElement,
    estimateSize: () => 750,
    getItemKey: (index) => sorted[index]?.id ?? "<empty>",
    measureElement: (element) => element.children[0]?.getBoundingClientRect?.().height ?? 0,
    gap: 8,
    overscan: 16,
  });

  React.useEffect(() => {
    setVirtualizer(rowVirtualizer);
    // Optional: Clean up on unmount
    return () => {
      setVirtualizer(null);
    };
  }, [rowVirtualizer, setVirtualizer]);

  // Only clear cursor when feed type changes
  React.useEffect(() => {
    if (feedType !== prevFeedType.current) {
      prevFeedType.current = feedType;
      setCurrentCursor(getCursor(feedType));
      setUseDummyData(false);
      setReachedEnd(false);
      setLastFetchedCursor(null);
      setPreviousItemsCount(0);

      // Reset query
      refetch({
        filter: { feed_type: feedType },
        dummy_data: false,
        cursor: getCursor(feedType),
      });
    }
  }, [feedType]);

  // Improved error handling and loading states
  if (error) {
    console.error("News feed error:", error);
    return <div>Error loading news feed: {error.message}</div>;
  }

  if (loading && !newsFeedResult) {
    console.log("News feed loading...");
    return <SpinnerCentered />;
  }

  if (newsFeedResult?.__typename === "L_Simple_Response_Error") {
    console.error("News feed error:", newsFeedResult.human_readable_error);
    return <div>Error: {newsFeedResult.human_readable_error}</div>;
  }

  if (newsFeedResult?.__typename === "L_News_Feed_Bad_Cursor") {
    console.log("Invalid cursor detected, clearing cursor state");
    clearCursor();
    setCurrentCursor(null);
    return <div>Invalid cursor detected. Refreshing feed...</div>;
  }

  if (!sorted.length) {
    console.log("No items in feed");
    return (
      <EmptyState
        isProd={Boolean(isProd)}
        setUseDummyData={setUseDummyData}
        navigate={navigate}
        setFeedType={setFeedType}
        feedType={feedType}
        rowVirtualizer={rowVirtualizer}
      />
    );
  }

  return (
    <div className="absolute inset-0 max-h-full overflow-y-hidden min-h-0 pb-10">
      <ScrollToTopButton containerRef={scrollParentRef} />
      <div className="px-4">
        <FeedTypeSwitcher
          feedType={feedType}
          setFeedType={setFeedType}
          rowVirtualizer={rowVirtualizer}
        />
      </div>
      <div ref={scrollParentRef} className="relative h-full max-h-full min-h-0 overflow-y-auto">
        <Container size="xs" className="h-full flex flex-col items-center pb-16 text-white">
          <div
            className="w-full max-w-full space-y-3 pb-16"
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative",
            }}
          >
            <ErrorBoundary FallbackComponent={FeedFallback}>
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const item = sorted[virtualRow.index];
                return (
                  <div
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={rowVirtualizer.measureElement}
                    style={{
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${Math.max(virtualRow.start)}px)`,
                    }}
                  >
                    {item && (
                      <>
                        <NewsFeedItem disableDeepLinks={useDummyData} item={item} />
                        <VisibilityObserver
                          onVisibility={(b) =>
                            b && window.history.replaceState({ newsFeedItemId: item.id }, "")
                          }
                        />
                      </>
                    )}
                    {virtualRow.index === sorted.length - 1 && (
                      <VisibilityObserver
                        onVisibility={onAtBottom}
                        className="h-1 pb-16 mx-auto col-span-3 w-1"
                      />
                    )}
                  </div>
                );
              })}
            </ErrorBoundary>
          </div>
        </Container>
      </div>
    </div>
  );
}

function FeedFallback(): React.JSX.Element {
  return (
    <div className="w-full h-full flex-col-center-center bg-primary">
      <Spinner />
    </div>
  );
}

function EmptyState({
  isProd,
  setUseDummyData,
  navigate,
  feedType,
  setFeedType,
  rowVirtualizer,
}: {
  isProd: boolean;
  setUseDummyData: (val: boolean) => void;
  navigate: (path: string) => void;
  feedType: L_News_Feed_Type;
  setFeedType: (type: L_News_Feed_Type) => void;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>; // Define the type
}) {
  return (
    <Container className="flex flex-col gap-2 bg-[#2A2C38] h-full pb-10">
      <FeedTypeSwitcher
        feedType={feedType}
        setFeedType={setFeedType}
        rowVirtualizer={rowVirtualizer}
      />
      <SimmerZeroScreen
        header="No connections yet"
        text="Don't worry, you're just getting started! Discover people to connect with and expand your network."
        btnText={isProd ? "Find Connections" : "Dev User: Use dummy data"}
        clickHandler={() =>
          isProd ? navigate(allRoutes.DISCOVERY.buildPath({})) : setUseDummyData(true)
        }
      />
    </Container>
  );
}

function FeedTypeSwitcher({
  feedType,
  setFeedType,
  rowVirtualizer,
}: {
  feedType: L_News_Feed_Type;
  setFeedType: (type: L_News_Feed_Type) => void;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>; // Define the type
}) {
  const feedTypes = [
    // { e: L_News_Feed_Type.DiscoveryOnly, t: "Discover" },
    { e: L_News_Feed_Type.ConnectionsOnly, t: "Connections" },
    { e: L_News_Feed_Type.StatusOnly, t: "Statuses" },
  ];

  return (
    <Container size="xs" className="mb-1 mt-4">
      <div className={clsx("flex flex-row justify-between gap-4")}>
        {feedTypes.map((item) => (
          <button
            key={item.e}
            onClick={() => {
              console.log("Switching to feed type:", item.e);
              setFeedType(item.e);
              jumpToTop(rowVirtualizer);
            }}
            className={clsx(
              "text-sm px-2 py-2 w-1/3 rounded-xl transition-all flex-1 text-center",
              feedType === item.e ? "bg-[#FF424D] text-white" : "bg-[#696969]/40 text-white"
            )}
          >
            {item.t}
          </button>
        ))}
      </div>
    </Container>
  );
}

export function jumpToTop<TElement extends Element>(
  virtualizer: Virtualizer<TElement, Element>
): void {
  virtualizer.scrollToIndex(0, { align: "start" });
}

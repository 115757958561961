import { BlockedUsers } from "./safety";

import { Container } from "../../../widgets/container";
import { DatingSettings } from "./dating/dating-main";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../../util/routes";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "../../../components/atoms/Text";
import { GqlOps } from "shared/dist/__generated__/components";
import { useApolloClient } from "shared/dist/apollo";

export function DatingSettingsPage(): React.JSX.Element {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  const handleGoBack = () => {
    // Invalidate the discovery cache before navigating back
    apolloClient.refetchQueries({
      include: [GqlOps.Query.DiscoveryResults542],
    });

    navigate(-1);
  };

  return (
    <Container size="sm" className={clsx("p-2")}>
      <div className="w-full flex -ml-3 mb-5 items-center text-white" onClick={handleGoBack}>
        <FontAwesomeIcon icon={faAngleLeft} fixedWidth size="2x" color="white" />
        <Text as="h3" size="xs" weight="semibold" className="pt-0">
          Dating Preferences
        </Text>
      </div>
      <DatingSettings />
      <div className="divider" />
    </Container>
  );
}
